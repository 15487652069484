import React,{useState, useEffect} from 'react'
import Footer from "../../components/Footer/FooterNew";
import { CarCover, TwoWheelerCover, SelfHealthCover, FamilyFloaterHealthCover, LifeTermCover, LifeInvestmentCover,Container,Content,Image,Section,Heading,SubHeading } from "./styles";
import CarCoverImg from "../../assets/products/car-cover.svg";
import TwoWheelerCoverImg from "../../assets/products/bike.svg";
import SelfHealthCoverImg from "../../assets/products/self-health-cover.svg";
import FamilyFloaterHealthCoverImg from "../../assets/products/famil-floater-cover.svg";
import LifeTermCoverImg from "../../assets/products/life-term-cover.svg";
import LifeInvestmentCoverImg from "../../assets/products/life-investment-cover.svg";
import { useNavigate } from "react-router-dom";
import {Modal} from 'antd'
import { Contact } from '../../components/insurance-contact/Contact';
import { Helmet } from 'react-helmet';
import Hero  from '../../components/Hero_Banner/hero';
import contactBanner from '../../assets/brand-banner.png';
import Quick  from '../../components/new_quick_links/quick';
import { MdConnectWithoutContact } from 'react-icons/md';

import "./products.css"

export const Products = () => {
  const[show,setShow]=useState(false)
  const [expandedIndexes, setExpandedIndexes] = useState([]);
    const navigate = useNavigate();
    const handleModal=()=>{
      setShow(!show)
      }

      // for view more 
  const handleExpand = (index) => {
    setExpandedIndexes((prevIndexes) => [...prevIndexes, index]);
  };


    useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // view less
    const handleCollapse = (index) => {
    setExpandedIndexes((prevIndexes) =>
      prevIndexes.filter((item) => item !== index)
    );
  };

  const isExpanded = (index) => expandedIndexes.includes(index);

  return (
    <div>
      <Hero
        image={contactBanner}
        heading={'Personal Insurance covers'}
        subHeading={'Protecting Your Personal Assets and Well-being'}
        btn={false}
        icon={<MdConnectWithoutContact size={100} />}
      />
      <Quick heading={'Individual Insurance'} />
      <Helmet>
        <meta
          name="title"
          content="Discover Insurance Solutions for Your Every Need and Peace of Mind"
        />
        <meta
          name="description"
          content="Dive into a variety of insurance products tailored to your needs. SimpliInsure.com offers a range of coverage options to secure your life, health, property, and more."
        />
        <meta
          property="og:title"
          content="Discover Insurance Solutions for Your Every Need and Peace of Mind"
        />
        <meta
          property="og:description"
          content="Dive into a variety of insurance products tailored to your needs. SimpliInsure.com offers a range of coverage options to secure your life, health, property, and more."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.SimpliInsure.com.com/products"
        />
        <meta
          property="og:image"
          content="https://drive.google.com/file/d/1RlhmE5gczxOTd2dGksyMBvv4qEnnmGUF/view?usp=drive_link"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Discover Insurance Solutions for Your Every Need and Peace of Mind"
        />
        <meta
          name="twitter:description"
          content="Dive into a variety of insurance products tailored to your needs. SimpliInsure.com offers a range of coverage options to secure your life, health, property, and more."
        />
        <meta
          name="twitter:image"
          content="https://drive.google.com/file/d/1RlhmE5gczxOTd2dGksyMBvv4qEnnmGUF/view?usp=drive_link"
        />
      </Helmet>
      <Container>
        {/* <Heading className='colorHead'>Personal Insurance covers</Heading> */}
        <Section>
          <Content>
            <SubHeading>
              <p>Private Car cover</p>
            </SubHeading>
            <div className={`paragraph ${isExpanded(0) ? 'expanded' : ''}`}>
              <p>
                Private Car cover, also known as personal auto insurance,
                provides financial protection for privately-owned vehicles used
                for personal purposes. It includes essential coverages such as
                third-party liability, which pays for damages to others in
                accidents you cause. Own damage coverage protects your vehicle
                from accidents, theft, vandalism, and natural disasters.
                Personal accident coverage compensates for injuries or death of
                you and your passengers. Medical expenses coverage covers
                treatment costs for accident-related injuries. Optional add-ons
                may include roadside assistance and coverage for legal expenses
                or personal belongings. Premium costs vary based on factors like
                vehicle type, driver's age and history, location, and desired
                coverage. Carefully review policy terms, conditions, and
                exclusions to understand your specific coverage. Consult an
                insurance professional or refer to your policy documents for
                accurate information.
                <br />
                <a href="/car-insurance">Click to go to car insurance page</a>
              </p>
            </div>

            {isExpanded(0) ? (
              <p className="read-less" onClick={() => handleCollapse(0)}>
                ...Read Less
              </p>
            ) : (
              <p className="read-more" onClick={() => handleExpand(0)}>
                Read More...
              </p>
            )}
          </Content>
          <Image>
            <CarCover src={CarCoverImg} />
          </Image>
        </Section>
        <Section>
          <Content>
            <SubHeading>
              <p>Two Wheeler cover</p>
            </SubHeading>
            <div className={`paragraph ${isExpanded(1) ? 'expanded' : ''}`}>
              <p>
                Two Wheeler cover, or motorcycle insurance, provides financial
                protection for motorbikes and scooters. It includes third-party
                liability for damage or injury caused to others, own damage
                coverage for repairs or replacement due to accidents or theft,
                and personal accident coverage for rider injury or death.
                Optional coverages may include accessories, roadside assistance,
                and medical expenses. Premium costs depend on factors like
                vehicle type, rider's history, and desired coverage. Comply with
                local regulations and review policy terms for accurate
                information. Two Wheeler cover ensures peace of mind and
                financial security while riding your two-wheeler.
                <br />
                <a onClick={handleModal}>
                  Click to go to two wheeler insurance page
                </a>
              </p>
            </div>
            {isExpanded(1) ? (
              <p className="read-less" onClick={() => handleCollapse(1)}>
                ...Read Less
              </p>
            ) : (
              <p className="read-more" onClick={() => handleExpand(1)}>
                Read More...
              </p>
            )}
          </Content>
          <Image>
            <TwoWheelerCover src={TwoWheelerCoverImg} />
          </Image>
        </Section>
        <Section>
          <Content>
            <SubHeading>
              <p>Self Health cover</p>
            </SubHeading>
            <div className={`paragraph ${isExpanded(2) ? 'expanded' : ''}`}>
              <p>
                Self Health cover, also known as individual health insurance or
                personal health insurance, provides financial protection for
                individuals' medical expenses and healthcare costs. It typically
                includes coverage for medical expenses, emergency care,
                specialist consultations, and preventive care. The specific
                coverage and benefits vary depending on the insurance provider
                and plan chosen. Premium costs depend on factors like age,
                pre-existing conditions, coverage limits, and optional add-ons.
                It's important to review policy documents to understand coverage
                details, exclusions, deductibles, and co-payments associated
                with Self Health cover. Waiting periods and limitations may
                apply to certain treatments, and pre-existing conditions may
                have specific coverage considerations. Consult with an insurance
                professional or refer to policy documents for accurate and
                specific information about your Self Health cover.
                <a href={process.env.REACT_APP_HEALTH_URL} target="_blanks">
                  {' '}
                  click here to go to health insurance
                </a>
              </p>
            </div>
            {isExpanded(2) ? (
              <p className="read-less" onClick={() => handleCollapse(2)}>
                ...Read Less
              </p>
            ) : (
              <p className="read-more" onClick={() => handleExpand(2)}>
                Read More...
              </p>
            )}
          </Content>
          <Image>
            <SelfHealthCover src={SelfHealthCoverImg} />
          </Image>
        </Section>
        <Section>
          <Content>
            <SubHeading>
              <p>Family floater health cover</p>
            </SubHeading>
            <div className={`paragraph ${isExpanded(3) ? 'expanded' : ''}`}>
              <p>
                Family floater health cover is an insurance policy that provides
                shared health coverage for an entire family under a single plan.
                It typically includes coverage for the whole family, with a
                shared sum insured that can be utilized by any family member.
                The policy covers medical expenses, hospitalization, pre and
                post-hospitalization expenses, and may include maternity and new
                born coverage. The premium cost depends on factors like the
                number of family members, their ages, pre-existing conditions,
                and desired coverage limits. Reviewing policy terms is important
                to understand coverage details, exclusions, waiting periods,
                deductibles, and co-payments. Consulting with an insurance
                professional will help you choose a suitable family floater
                health cover that meets your family's healthcare needs.
              </p>
            </div>
            {isExpanded(3) ? (
              <p className="read-less" onClick={() => handleCollapse(3)}>
                ...Read Less
              </p>
            ) : (
              <p className="read-more" onClick={() => handleExpand(3)}>
                Read More...
              </p>
            )}
          </Content>
          <Image>
            <FamilyFloaterHealthCover src={FamilyFloaterHealthCoverImg} />
          </Image>
        </Section>
        <Section>
          <Content>
            <SubHeading>
              <p>Life term cover</p>
            </SubHeading>
            <div className={`paragraph ${isExpanded(4) ? 'expanded' : ''}`}>
              <p>
                Life term cover, also known as term life insurance, is a
                straightforward and affordable type of life insurance policy. It
                provides a death benefit to beneficiaries if the policyholder
                passes away during a specified term. Key features include a
                fixed term of coverage, affordable premiums, and the option to
                convert to a permanent policy. It aims to provide financial
                protection for loved ones, replace lost income, and cover debts
                or future expenses. Reviewing policy terms, exclusions, and
                premium payment schedule is important. Consulting with an
                insurance professional helps in selecting the right life term
                cover policy based on individual needs and goals.
                <a href="/term-insurance">
                  {' '}
                  click here to go to life insurance page
                </a>
              </p>
            </div>
            {isExpanded(4) ? (
              <p className="read-less" onClick={() => handleCollapse(4)}>
                ...Read Less
              </p>
            ) : (
              <p className="read-more" onClick={() => handleExpand(4)}>
                Read More...
              </p>
            )}
          </Content>
          <Image>
            <LifeTermCover src={LifeTermCoverImg} />
          </Image>
        </Section>
        <Section>
          <Content>
            <SubHeading>
              <p>Life investment-related cover</p>
            </SubHeading>
            <div className={`paragraph ${isExpanded(5) ? 'expanded' : ''}`}>
              <p>
                Life investment-related cover combines life insurance protection
                with an investment component. It offers a death benefit to
                beneficiaries and allows policyholders to invest a portion of
                their premiums in various investment funds or portfolios. Key
                features include life insurance coverage, investment
                opportunities, flexibility in fund selection, and potential cash
                value accumulation. Policyholders can customize their investment
                strategy and may have access to the cash value or surrender the
                policy. It is important to review the policy terms, including
                fees and charges, and consult with a financial advisor to
                understand the suitability and potential risks associated with
                these policies based on individual financial goals. Availability
                and specific features may vary depending on the insurance
                company and jurisdiction.
              </p>
            </div>
            {isExpanded(5) ? (
              <p className="read-less" onClick={() => handleCollapse(5)}>
                ...Read Less
              </p>
            ) : (
              <p className="read-more" onClick={() => handleExpand(5)}>
                Read More...
              </p>
            )}
          </Content>
          <Image>
            <LifeInvestmentCover src={LifeInvestmentCoverImg} />
          </Image>
        </Section>
        {/* <div>
        <p>Personal Insurance covers</p>
        <div style={{ display: "flex" }}>
          <div>
            <p>Private Car cover</p>
            <p>
              Private Car cover, also known as personal auto insurance, provides
              financial protection for privately-owned vehicles used for personal
              purposes. It includes essential coverages such as third-party
              liability, which pays for damages to others in accidents you cause.
              Own damage coverage protects your vehicle from accidents, theft,
              vandalism, and natural disasters. Personal accident coverage
              compensates for injuries or death of you and your passengers.
              Medical expenses coverage covers treatment costs for
              accident-related injuries. Optional add-ons may include roadside
              assistance and coverage for legal expenses or personal belongings.
              Premium costs vary based on factors like vehicle type, driver's age
              and history, location, and desired coverage. Carefully review policy
              terms, conditions, and exclusions to understand your specific
              coverage. Consult an insurance professional or refer to your policy
              documents for accurate information.{" "}
              <a href="">Click here to go car insurance</a>
            </p>
          </div>
          <div style={{ float: "right" }}>
            <CarCover src={CarCoverImg} />
          </div>
        </div>
      </div> */}
        {/* <div>
        <div>
          <p>Two Wheeler cover</p>
          <p>
            Two Wheeler cover, or motorcycle insurance, provides financial
            protection for motorbikes and scooters. It includes third-party
            liability for damage or injury caused to others, own damage coverage
            for repairs or replacement due to accidents or theft, and personal
            accident coverage for rider injury or death. Optional coverages may
            include accessories, roadside assistance, and medical expenses.
            Premium costs depend on factors like vehicle type, rider's history,
            and desired coverage. Comply with local regulations and review
            policy terms for accurate information. Two Wheeler cover ensures
            peace of mind and financial security while riding your two-wheeler.{" "}
            <a>Click here to go two wheeler insurance</a>
          </p>
        </div>
        <div>
          <TwoWheelerCover src={TwoWheelerCoverImg} />
        </div>
      </div> */}
        {/* <div>
        <div>
          <p>Self Health cover</p>
          <p>
            Self Health cover, also known as individual health insurance or
            personal health insurance, provides financial protection for
            individuals' medical expenses and healthcare costs. It typically
            includes coverage for medical expenses, emergency care, specialist
            consultations, and preventive care. The specific coverage and
            benefits vary depending on the insurance provider and plan chosen.
            Premium costs depend on factors like age, pre-existing conditions,
            coverage limits, and optional add-ons. It's important to review
            policy documents to understand coverage details, exclusions,
            deductibles, and co-payments associated with Self Health cover.
            Waiting periods and limitations may apply to certain treatments, and
            pre-existing conditions may have specific coverage considerations.
            Consult with an insurance professional or refer to policy documents
            for accurate and specific information about your Self Health cover.{" "}
            <a href=""> click here to go to health insurance</a>
          </p>
        </div>
        <div>
          <SelfHealthCover src={SelfHealthCoverImg} />
        </div>
      </div> */}
        {/* <div>
        <div>
          <p>Family floater health cover</p>
          <p>
            Family floater health cover is an insurance policy that provides
            shared health coverage for an entire family under a single plan. It
            typically includes coverage for the whole family, with a shared sum
            insured that can be utilized by any family member. The policy covers
            medical expenses, hospitalization, pre and post-hospitalization
            expenses, and may include maternity and new born coverage. The
            premium cost depends on factors like the number of family members,
            their ages, pre-existing conditions, and desired coverage limits.
            Reviewing policy terms is important to understand coverage details,
            exclusions, waiting periods, deductibles, and co-payments.
            Consulting with an insurance professional will help you choose a
            suitable family floater health cover that meets your family's
            healthcare needs.
          </p>
        </div>
        <div>
          <FamilyFloaterHealthCover src={FamilyFloaterHealthCoverImg} />
        </div>
      </div> */}
        {/* <div>
        <div>
          <p>Life term cover</p>
          <p>
            Life term cover, also known as term life insurance, is a straightforward and affordable type of life insurance policy. It provides a death benefit to beneficiaries if the policyholder passes away during a specified term. Key features include a fixed term of coverage, affordable premiums, and the option to convert to a permanent policy. It aims to provide financial protection for loved ones, replace lost income, and cover debts or future expenses. Reviewing policy terms, exclusions, and premium payment schedule is important. Consulting with an insurance professional helps in selecting the right life term cover policy based on individual needs and goals.
          </p>
        </div>
        <div>
          <LifeTermCover src={LifeTermCoverImg} />
        </div>
      </div> */}
        {/* <div>
            <div>
            <p>Life investment-related cover</p>
            <p>
                Life investment-related cover combines life insurance protection with an investment component. It offers a death benefit to beneficiaries and allows policyholders to invest a portion of their premiums in various investment funds or portfolios. Key features include life insurance coverage, investment opportunities, flexibility in fund selection, and potential cash value accumulation. Policyholders can customize their investment strategy and may have access to the cash value or surrender the policy. It is important to review the policy terms, including fees and charges, and consult with a financial advisor to understand the suitability and potential risks associated with these policies based on individual financial goals. Availability and specific features may vary depending on the insurance company and jurisdiction.
            </p>
            </div>
            <div>
            <LifeInvestmentCover src={LifeInvestmentCoverImg} />
            </div>
        </div> */}
      </Container>
      <Footer />
      <Modal
        className="modal"
        title="Contact Us"
        open={show}
        onOk={handleModal}
        onCancel={handleModal}
        width={800}
      >
        <Contact handleModal={handleModal} />
      </Modal>
    </div>
  );
};
