import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserProfile } from '../../../../Redux/AuthRedux';
import moment from 'moment';
import './customerDetail.css';
import { GetCustomerFromList } from '../../../../Redux/DashboardRedux';
import PolicyData from './policyData';


const CustomerDetail = ({ _id, closeModal }) => {
  const dispatch = useDispatch();
  const { userProfileData } = useSelector((state) => state.auth);
  const posInfo = userProfileData?.data;

    const { customerDataFromList } = useSelector(
      (state) => state.dashboardRedux
    );

  const [activeTab, setActiveTab] = useState('Details');
  const [customerData, setCustomerData] = useState(null);

  useEffect(() => {
    if (_id) {
      dispatch(GetCustomerFromList({ _id }));
    }

  }, [dispatch, _id]);

  useEffect(() => {
    if (customerDataFromList) {
      setCustomerData(customerDataFromList.data[0]);
    }
  }, [customerDataFromList]);

  const tabs = ['Details', 'Policy',];

  const renderContent = () => {

     if (!customerData) {
       return <div>Loading...</div>;
     }
     
    switch (activeTab) {
      case 'Details':
        return <DetailsTab customerData={customerData} />;
      case 'Policy':
        return <PolicyData customerData={customerData}  />;
      // case 'Document':
      //   return <DocumentTab />;
      default:
        return null;
    }
  };


      console.log('🚀 ~ customerData:', customerData);

  return (
    <div className="customer-detail-page">
      <div className="menu-tabs">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={activeTab === tab ? 'active' : ''}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
        <button onClick={closeModal} className="close-button">
          &times;
        </button>
      </div>
      {renderContent()}
    </div>
  );
};

const DetailsTab = ({ customerData }) => (
  <div className="customer-card">
    <ProfileSection customerData={customerData} />
    <POSDetails customerData={customerData} />
    <OtherDetails customerData={customerData} />
    <KYCStatus kycStatus={customerData?.kycStatus} />
  </div>
);

const ProfileSection = ({ customerData }) => (
  <div className="profile-section">
    <img
      src="https://via.placeholder.com/150"
      className="profile-photo"
      alt="profile"
    />
    <div className="profile-info">
      <h2>{customerData?.fullName}</h2>
      <p>
        <strong>Name:</strong> {customerData?.fullName}
      </p>
      <p>
        <strong>Email:</strong> {customerData?.email}
      </p>
      <p>
        <strong>Phone:</strong> +91 - {customerData?.phone}
      </p>
      <p>
        <strong>Member Since:</strong>{' '}
        {customerData?.createdAt
          ? moment(customerData.createdAt).format('DD/MM/YYYY HH:mm')
          : '20/01/2020 00:00'}
      </p>
    </div>
  </div>
);

const POSDetails = ({ customerData }) => (
  <div className="details-section">
    <h3>POS Details</h3>
    <DetailRow label="Customer ID" value={customerData?.customerId} />
    <DetailRow label="House No." value={customerData?.address} />
    <DetailRow label="Branch Name" value={customerData?.branchName} />
    {/* <DetailRow label="Town" value={customerData?.town} /> */}
    <DetailRow label="District" value={`${customerData?.city} (Urban)`} />
    <DetailRow label="State" value={customerData?.state} />
    <DetailRow label="Area" value={customerData?.area} />
    <DetailRow label="Pincode" value={customerData?.pincode} />
  </div>
);

const OtherDetails = ({ posInfo }) => (
  <div className="details-section">
    <h3>Other Details</h3>
    <DetailRow
      label="Gender"
      value={posInfo?.profile?.gender === 'm' ? 'Male' : 'Female'}
    />
    <DetailRow
      label="D.O.B"
      value={
        posInfo?.profile?.dob
          ? moment(posInfo?.profile?.dob).format('DD/MM/YYYY')
          : 'Not available'
      }
    />
    <DetailRow label="Pan Card" value={posInfo?.panNo} />
    <DetailRow label="Basic Qualification" value={posInfo?.qualification} />
    <DetailRow
      label="IRDA Number"
      value={posInfo?.irdaNumber || 'Not Available'}
    />
    <DetailRow
      label="IRDA Document"
      value={
        posInfo?.irdaImg ? (
          <a href={posInfo.irdaImg} download>
            Download
          </a>
        ) : (
          'No document available'
        )
      }
    />
  </div>
);

const KYCStatus = ({ kycStatus }) => (
  <div className="kyc-status">
    <h3>KYC Status</h3>
    <p>
      <strong>Status:</strong> {kycStatus || 'Pending'}
    </p>
  </div>
);

const DetailRow = ({ label, value }) => (
  <div className="detail-row">
    <p>
      <strong>{label}:</strong> {value}
    </p>
  </div>
);

// const DocumentTab = () => (
//   <div className="document-section">
//     <h3>Document Details</h3>  
//     {/* Document details content goes here */}
//   </div>
// );

export default CustomerDetail;


