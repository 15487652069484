import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react';
import { FcOk } from 'react-icons/fc'
import { CiImageOn } from 'react-icons/ci'
import Sidebar from './Sidebar';
import { uploadFile } from "../uploadFile"
// import Review from './Review'
import './Upload.css'
import { BsCameraFill } from 'react-icons/bs'
import { AiFillFilePdf } from 'react-icons/ai';
import { useDispatch, useSelector } from "react-redux"
import { updateDocunents } from "../../../../src/Redux/posRedux"
import './commonPos.css'
import { axiosPUTCall, axiosPUTCall1 } from "../../../utilities/utils/apiPost"

import pdfImage from '../../../assets/pdf-file-image.png'

const Upload = () => {
  // const [items5, setItems5] = useState(true)
  const dispatch = useDispatch();
  const [items3, setItems3] = useState(true);
  const [items2, setItems2] = useState(true);
  const [items4, setItems4] = useState(true);
  const [toggler, setToggler] = useState(true);
  const posRedux = useSelector((state) => state.posRedux.posData);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [url, setUrl] = useState({
    profile: posRedux?.documents?.profile ? posRedux?.documents?.profile : '',
    aadhar: posRedux?.documents?.aadharFront
      ? posRedux?.documents?.aadharFront
      : '',
    aadharBack: posRedux?.documents?.aadharBack
      ? posRedux?.documents?.aadharBack
      : '',
    pan: posRedux?.documents?.pancard ? posRedux?.documents?.pancard : '',
    bankDoc: posRedux?.documents?.bankDoc ? posRedux?.documents?.bankDoc : '',
    qualification: posRedux?.documents?.education
      ? posRedux?.documents?.education
      : '',
    sign: posRedux?.documents?.signature ? posRedux?.documents?.signature : '',
  });

  const profileRef = useRef();
  const aadharRef = useRef();
  const aBackRef = useRef();
  const panRef = useRef();
  const bankDocRef = useRef();
  const docRef = useRef();
  const fileRef = useRef();
  const cameraRef = useRef();

    const [isPdf, setIsPdf] = useState({
      profile: false,
      aadhar: false,
      aadharBack: false,
      pan: false,
      bankDoc: false,
      qualification: false,
      sign: false,
    });

  const uploadProfile = async (e) => {
    // console.log("e.target.files[0]", e.target.files[0]);
    if (e.target.files[0].type === 'application/pdf') {
      setIsPdf((prev) => ({ ...prev, profile: true }));
    }

    if (e.target.files[0].size < 10485760) {
      const rl = await uploadFile(e.target?.files[0]);
      setUrl((prev) => ({ ...prev, profile: rl?.data[0]?.url }));
      profileRef.current.style.display = 'block';
      cameraRef.current.style.display = 'none';
    } else {
      alert('Please, Reduce the size of image (below 10 MB) ');
    }
  };
  const uploadAadhar = async (e) => {
    // console.log('aadhar front is ', e.target.files[0]);
    if (e.target.files[0].type === 'application/pdf') {
      setIsPdf((prev) => ({ ...prev, aadhar: true }));
    }

    const rl = await uploadFile(e.target.files[0]);
    setUrl((prev) => ({ ...prev, aadhar: rl.data[0].url }));
    aadharRef.current.style.display = 'block';
  };

  const uploadAadharBack = async (e) => {
    // console.log(e.target.files[0]);
    if (e.target.files[0].type === 'application/pdf') {
      setIsPdf((prev) => ({ ...prev, aadharBack: true }));
    }
    const rl = await uploadFile(e.target.files[0]);
    setUrl((prev) => ({ ...prev, aadharBack: rl.data[0].url }));
    aBackRef.current.style.display = 'block';
  };
  const uploadPan = async (e) => {
    // console.log(e.target.files[0]);
    if (e.target.files[0].type === 'application/pdf') {
      setIsPdf((prev) => ({ ...prev, pan: true }));
    }

    const rl = await uploadFile(e.target.files[0]);
    setUrl((prev) => ({ ...prev, pan: rl.data[0].url }));
    panRef.current.style.display = 'block';
  };

  const uploadBankDoc = async (e) => {
    // console.log(e.target.files[0]);
    if (e.target.files[0].type === 'application/pdf') {
      setIsPdf((prev) => ({ ...prev, bankDoc: true }));
    }

    const rl = await uploadFile(e.target.files[0]);
    setUrl((prev) => ({ ...prev, bankDoc: rl.data[0].url }));
    bankDocRef.current.style.display = 'block';
  };
  const uploadDoc = async (e) => {
    // console.log(e.target.files[0]);
    if (e.target.files[0].type === 'application/pdf') {
      setIsPdf((prev) => ({ ...prev, qualification: true }));
    }

    const rl = await uploadFile(e.target.files[0]);
    setUrl((prev) => ({ ...prev, qualification: rl.data[0].url }));
    docRef.current.style.display = 'block';
  };
  const uploadSign = async (e) => {
    // console.log(e.target.files[0]);
    if (e.target.files[0].type === 'application/pdf') {
      setIsPdf((prev) => ({ ...prev, sign: true }));
    }

    const rl = await uploadFile(e.target.files[0]);
    setUrl((prev) => ({ ...prev, sign: rl.data[0].url }));
    fileRef.current.style.display = 'block';
  };
  // handlechange()
  const navigate = useNavigate();
  const clickHandle = async () => {
    const obj = {
      documents: {
        profile: url.profile,
        aadharFront: url.aadhar,
        aadharBack: url.aadharBack,
        pancard: url.pan,
        bankDoc: url.bankDoc,
        education: url.qualification,
        signature: url.sign,
      },
      kycStatus: 'Submitted',
      posStatus: 'pending',
      examStatus: 'approved',
    };

    if (!url.profile) {
      alert('Please Upload Your Profile');
    } else if (!url.aadhar) {
      alert('Please Upload Your Aadhar Card Front');
    } else if (!url.pan) {
      alert('Please Upload Your Pan Card');
    } else if (!url.qualification) {
      alert('Please Upload Your Qualification Documents');
    } else if (!url.sign) {
      alert('Please Upload Your Signature');
    } else {
      if (userDetails.roles[0] === "admin") {
        // const irdaNumber = localStorage.getItem('irdaReviewNumber');

      axiosPUTCall1('pospInfo/' + posRedux?._id, obj, (callBack) => {
        if (callBack.status == 'Success') {
          dispatch(updateDocunents(callBack.data));
          
          // localStorage.removeItem('irdaReviewNumber');

          navigate('/done');
          // navigate('/pos', { state: { examStatus: 'approved' } });
        }
      });
      setItems4(false);
    } else {
       axiosPUTCall1('pospInfo/' + posRedux?._id, obj, (callBack) => {
         if (callBack.status == 'Success') {
           dispatch(updateDocunents(callBack.data));

           const source = localStorage.getItem('sourcePage');

           if (source === 'adminDash') {
             navigate('/dashboard/agent');
             localStorage.removeItem('sourcePage');
           } else {
             navigate('/done');
           }
         }
       });
       setItems4(false);
    }
  }
  };
  useEffect(() => {
    if (Object.keys(posRedux).length <= 0) {
      navigate('/partner');
    } else if (
      !posRedux.gender ||
      !posRedux.dob ||
      !posRedux.email ||
      !posRedux.panNo ||
      !posRedux.qualification ||
      !posRedux.phone
    ) {
      navigate('/details');
    } else if (!posRedux?.language?.training || !posRedux?.language?.exam) {
      navigate('/lang');
    } else if (!posRedux?.segment) {
      navigate('/sengment');
    } else if (
      !posRedux?.documents?.profile ||
      !posRedux?.documents?.aadharFront ||
      !posRedux?.documents?.pancard ||
      !posRedux?.documents?.education ||
      !posRedux?.documents?.signature
    ) {
      navigate('/documents');
    }
  }, []);

  const cancelHandler = () => {
    const fromAdminDash = localStorage.getItem('addPos');

    if (fromAdminDash) {
      navigate('/dashboard/agent');
      localStorage.removeItem('addPos');
    } else {
      navigate('/work-with-us');
    }
  };

  return (
    <>
      <div className="mainWrapper">
        <div className="containerContent">
          <div className="cancelBtn" onClick={cancelHandler}>
            &times;
          </div>

          <Sidebar
            items4={items4}
            setItems4={setItems4}
            items2={items2}
            setItems2={setItems2}
            items3={items3}
            setItems3={setItems3}
          />
          <div className="pos_input_container">
            <h3>Upload Documents :</h3>
            <div className="document">
              <div className="camera">
                <div className="cameras" style={{ cursor: 'pointer' }}>
                  <div className="icon">
                    <span>
                      <label
                        htmlFor="id"
                        className="center_flex"
                        ref={cameraRef}
                      >
                        <BsCameraFill />
                      </label>
                      <input
                        style={{ cursor: 'pointer' }}
                        type="file"
                        id="id"
                        onChange={uploadProfile}
                      />
                      <img
                         src={isPdf.profile === true ? pdfImage : url.profile}
                         alt="profile"
                         className="profile_image"
                          ref={profileRef}
                    />
                    </span>
                  </div>
                </div>
                <h5>Add image</h5>
              </div>
              <div className="documents">
                <div className="main_container">
                  <h4>Aadhar Card :</h4>
                  <div className="upload_documents">
                    <div className="sideContainer">
                      <div className="imageViewer">
                        <div className="upload_image">
                          {' '}
                          <CiImageOn />{' '}
                        </div>
                        <input type="file" id="id1" onChange={uploadAadhar} />
                        <label htmlFor="id1" className="logo">
                          <p>Click here</p>
                              <img
                                src= {isPdf.aadhar === true ? pdfImage : url.aadhar}
                                alt="adhar"
                                className="document_image"
                                ref={aadharRef}
                              />

                        
                        </label>
                        <p>To Upload Your Aadhar Card Front</p>
                      </div>
                    </div>
                    <div className="sideContainer">
                      <div className="imageViewer">
                        <div className="upload_image">
                          {' '}
                          <CiImageOn />{' '}
                        </div>
                        <input
                          type="file"
                          id="id2"
                          onChange={uploadAadharBack}
                        />
                        <label htmlFor="id2" className="logo">
                          <p>Click here</p>
                              <img
                                src= {isPdf.aadharBack === true ? pdfImage : url.aadharBack}
                                alt="aadharBack"
                                className="document_image"
                                ref={aBackRef}
                              />
                         
                        </label>
                        <p>To Upload Your Aadhar Card Back</p>
                        <p>(Optional)</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="upload_documents">
                  <div className="main_container">
                    <div className="sideContainer">
                      <h4>Pan Card :</h4>
                      <div className="imageViewer">
                        <div className="upload_image">
                          {' '}
                          <CiImageOn />{' '}
                        </div>
                        <input type="file" id="id3" onChange={uploadPan} />
                        <label htmlFor="id3" className="logo">
                          <p>Click here</p>
                              <img
                                src= {isPdf.pan === true ? pdfImage : url.pan}
                                alt="pan"
                                className="document_image"
                                ref={panRef}
                              />
                         
                        </label>
                        <p>To Upload Your PAN Card Front</p>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div className="main_container">
                    <div className="sideContainer">
                      <h4>Bank Statement :</h4>
                      <div className="imageViewer">
                        <div className="upload_image">
                          {' '}
                          <CiImageOn />{' '}
                        </div>
                        <input type="file" id="id31" onChange={uploadBankDoc} />
                        <label htmlFor="id31" className="logo">
                          <p>Click here</p>
                        
                              <img
                                src= {isPdf.bankDoc === true ? pdfImage : url.bankDoc}
                                alt="bankDoc"
                                className="document_image"
                                ref={bankDocRef}
                              />
                        
                        </label>
                        <p>To Upload Your Bank Doc</p>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div className="main_container">
                  <h4>Education & Signature :</h4>
                  <div className="upload_documents">
                    <div className="sideContainer">
                      <div className="imageViewer">
                        <div className="upload_image">
                          {' '}
                          <CiImageOn />{' '}
                        </div>
                        <input type="file" id="id4" onChange={uploadDoc} />
                        <label htmlFor="id4" className="logo">
                          <p>Click here</p>
                              <img
                                src= {isPdf.qualification === true ? pdfImage : url.qualification}
                                alt="qualification"
                                className="document_image"
                                ref={docRef}
                              />
                         
                        </label>
                        <p>To Upload Your Education Qualification</p>
                      </div>
                    </div>
                    <div className="sideContainer">
                      <div className="imageViewer">
                        <div className="upload_image">
                          {' '}
                          <CiImageOn />{' '}
                        </div>
                        <input type="file" id="id5" onChange={uploadSign} />
                        <label htmlFor="id5" className="logo">
                          <p>Click here</p>
                        
                              <img
                                src= {isPdf.sign === true ? pdfImage : url.sign}
                                alt="sign"
                                className="document_image"
                                ref={fileRef}
                              />

                        </label>
                        <p>To Upload Your Signature</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn_register">
              <button step={5} onClick={clickHandle}>
                NEXT
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Upload


// import React, { useState, useEffect, useRef } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { FcOk } from 'react-icons/fc';
// import { CiImageOn } from 'react-icons/ci';
// import { BsCameraFill } from 'react-icons/bs';
// import { AiFillFilePdf } from 'react-icons/ai'; // PDF icon
// import Sidebar from './Sidebar';
// import { uploadFile } from '../uploadFile';
// import { useDispatch, useSelector } from 'react-redux';
// import { updateDocunents } from '../../../../src/Redux/posRedux';
// import { axiosPUTCall1 } from '../../../utilities/utils/apiPost';
// import './Upload.css';
// import './commonPos.css';

// const Upload = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const posRedux = useSelector((state) => state.posRedux.posData);

//   const [url, setUrl] = useState({
//     profile: posRedux?.documents?.profile || '',
//     aadhar: posRedux?.documents?.aadharFront || '',
//     aadharBack: posRedux?.documents?.aadharBack || '',
//     pan: posRedux?.documents?.pancard || '',
//     bankDoc: posRedux?.documents?.bankDoc || '',
//     qualification: posRedux?.documents?.education || '',
//     sign: posRedux?.documents?.signature || '',
//   });

//   const [isPdf, setIsPdf] = useState({
//     profile: false,
//     aadhar: false,
//     aadharBack: false,
//     pan: false,
//     bankDoc: false,
//     qualification: false,
//     sign: false,
//   });

//   const handleFileUpload = async (e, type) => {
//     const file = e.target.files[0];
//     if (file) {
//       const isPdfFile = file.type === 'application/pdf';
//       setIsPdf((prev) => ({ ...prev, [type]: isPdfFile }));

//       if (!isPdfFile && file.size < 10485760) {
//         const rl = await uploadFile(file);
//         setUrl((prev) => ({ ...prev, [type]: rl?.data[0]?.url }));
//       } else if (isPdfFile) {
//         alert('PDF Uploaded Successfully');
//       } else {
//         alert('Please, reduce the size of the file (below 10 MB)');
//       }
//     }
//   };

//   const clickHandle = async () => {
//     const obj = {
//       documents: {
//         profile: url.profile,
//         aadharFront: url.aadhar,
//         aadharBack: url.aadharBack,
//         pancard: url.pan,
//         bankDoc: url.bankDoc,
//         education: url.qualification,
//         signature: url.sign,
//       },
//       kycStatus: 'Submitted',
//       posStatus: 'pending',
//       examStatus: 'approved',
//     };
//     if (!url.profile) {
//       alert('Please Upload Your Profile');
//     } else if (!url.aadhar) {
//       alert('Please Upload Your Aadhar Card Front');
//     } else if (!url.pan) {
//       alert('Please Upload Your Pan Card');
//     } else if (!url.qualification) {
//       alert('Please Upload Your Qualification Documents');
//     } else if (!url.sign) {
//       alert('Please Upload Your Signature');
//     } else {
//       axiosPUTCall1('pospInfo/' + posRedux._id, obj, (callBack) => {
//         if (callBack.status === 'Success') {
//           dispatch(updateDocunents(callBack.data));
//           navigate('/pos', { state: { examStatus: 'approved' } });
//         }
//       });
//     }
//   };

//   const cancelHandler = () => {
//     const fromAdminDash = localStorage.getItem('addPos');
//     if (fromAdminDash) {
//       navigate('/dashboard/agent');
//       localStorage.removeItem('addPos');
//     } else {
//       navigate('/work-with-us');
//     }
//   };

//   return (
//     <div className="mainWrapper">
//       <div className="containerContent">
//         <div className="cancelBtn" onClick={cancelHandler}>
//           &times;
//         </div>

//         <Sidebar />
//         <div className="pos_input_container">
//           <h3>Upload Documents :</h3>
//           <div className="document">
//             {/* Profile Upload */}
//             <div className="camera">
//               <div className="cameras" style={{ cursor: 'pointer' }}>
//                 <div className="icon">
//                   <span>
//                     <label htmlFor="profile" className="center_flex">
//                       <BsCameraFill />
//                     </label>
//                     <input
//                       type="file"
//                       id="profile"
//                       onChange={(e) => handleFileUpload(e, 'profile')}
//                     />
//                     {isPdf.profile ? (
//                       <AiFillFilePdf className="pdf_icon" />
//                     ) : (
//                       <img
//                         src={url.profile}
//                         alt="profile"
//                         className="profile_image"
//                       />
//                     )}
//                   </span>
//                 </div>
//               </div>
//               <h5>Add image</h5>
//             </div>

//             {/* Aadhar Card Upload */}
//             <div className="upload_documents">
//               <div className="main_container">
//                 <h4>Aadhar Card :</h4>
//                 <div className="sideContainer">
//                   <div className="imageViewer">
//                     <input
//                       type="file"
//                       id="aadhar"
//                       onChange={(e) => handleFileUpload(e, 'aadhar')}
//                     />
//                     {isPdf.aadhar ? (
//                       <AiFillFilePdf className="pdf_icon" />
//                     ) : (
//                       <img
//                         src={url.aadhar}
//                         alt="aadhar"
//                         className="document_image"
//                       />
//                     )}
//                   </div>
//                 </div>
//                 <div className="sideContainer">
//                   <div className="imageViewer">
//                     <input
//                       type="file"
//                       id="aadharBack"
//                       onChange={(e) => handleFileUpload(e, 'aadharBack')}
//                     />
//                     {isPdf.aadharBack ? (
//                       <AiFillFilePdf className="pdf_icon" />
//                     ) : (
//                       <img
//                         src={url.aadharBack}
//                         alt="aadharBack"
//                         className="document_image"
//                       />
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Other Document Uploads */}
//             {/* Add similar sections for Pan, Bank Statement, Qualification, and Signature */}
//           </div>

//           <div className="btn_register">
//             <button onClick={clickHandle}>NEXT</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Upload;
