import React, {useState, useEffect} from 'react';
import cardData from './posCardData';
import { useNavigate, Link } from 'react-router-dom';
import { axiosGETCall1 } from "../../../utilities/utils/apiPost";
import { useSelector } from 'react-redux';


const PosCard = ({rejectReasons, finalUserData}) => {
  const navigate = useNavigate();
  const [isLogIn, setIsLogIn] = useState(false);
  const [isPos, setIsPos] = useState(true);
  const [status, setStatus] = useState({
    role: "",
    isUserPos: true,
    isUser: true,
    isKyc: "",
    isIrda: "",
  })
  const [isVerifiedPos, setIsVerifiedPos] = useState(false);
  const [posStatus, setPosStatus] = useState("NA");
  // const [userData, setUserData] = useState({});
  // var userData = localStorage.getItem('userDetails');
  // userData = JSON.parse(userData);

    useEffect(() => {
    var Authorization = localStorage.getItem("Authorization");
    if (Authorization) {
      setIsLogIn(true);
    } else {
      setIsLogIn(false);
    }
  }, []);

  useEffect(() => {
    if (finalUserData && Object.keys(finalUserData).length > 0 && finalUserData?._id) {
      if (finalUserData?.roles?.includes("user")) {
        setPosStatus(finalUserData.posStatus || "NA")
        setStatus(prev => ({ ...prev, role: finalUserData?.roles[0] }))
      }
      if (finalUserData.roles?.includes("pos")) {
        setStatus(prev => ({ ...prev, role: finalUserData?.roles[0], isUserPos: true, isUser: false }))
        setPosStatus(finalUserData.posStatus || "NA")
      }
      if (finalUserData?.roles?.includes("admin")) {
        setStatus(prev => ({ ...prev, role: "admin" }))
        setIsVerifiedPos(true)
      }
      else if (finalUserData?.roles?.includes("admin") && finalUserData.irdaStatus === "approved") {
        setStatus(prev => ({ ...prev, role: "admin", isIrda: true }))
        setIsVerifiedPos(true);
      }
    }
  }, [finalUserData]);
  
  const filteredCardData = cardData.filter(card => card.stage === posStatus);

  return (
    <>
      {!finalUserData?.roles?.includes('admin') && (
    <div className="pos-data-card simpli_border_card">
      <div className="pos-card-container">
        {/* Render filtered card */}
        {filteredCardData.map((card) => (
          <div className="pos-data-card" key={card.stage}>
            <h2>{card.title}</h2>
            {posStatus === 'rejected' && (
              <div className="rejected-reason">
                {rejectReasons && (
                  <div>
                    <p>Reason for rejection:</p>
                    <p>{rejectReasons}</p>
                  </div>
                )}
              </div>
            )}
            <p style={{ marginBottom: '20px' }}>{card.description}</p>

            {/* render button data */}

            {isLogIn ? (
              isLogIn &&
              !isVerifiedPos &&
              status.role !== 'admin' &&
              (status.isUserPos && posStatus === 'NA' ? (
                <Link to="/partner" className="pos-wwu-button pos">
                  Register as POS
                </Link>
              ) : status.isUser &&
                (posStatus === 'draft' || posStatus === 'NA') ? (
                <Link
                  to={
                    finalUserData?.posId
                      ? `/partner?id=${finalUserData.posId}`
                      : '/partner'
                  }
                  className="pos-wwu-button pos"
                >
                  Continue as POS
                </Link>
              ) : status.isUserPos && posStatus === 'pending' ? (
                <div className="header-menu pos statusPending">
                  POS Pending...
                </div>
              ) : status.isUserPos && posStatus === 'approved' ? (
                <Link to="/dashboard/agent" className="pos-wwu-button pos">
                  Start Selling
                </Link>
              ) : status.isUserPos && posStatus === 'rejected' ? (
                <Link to="/partner" className="pos-wwu-button pos">
                  Reapply Now
                </Link>
              ) : (
                <div
                  className="pos-wwu-button pos"
                  style={{ cursor: 'default' }}
                >
                  Not eligible for POS X_X
                </div>
              ))
            ) : (
              <div
                className="pos-wwu-button pos"
                onClick={() =>
                  navigate('/signin-signup?sourcePage=work-with-us')
                }
              >
                Login/Register as POS
              </div>
            )}

            {status.role === 'admin' || posStatus === 'approved' ? (
              ' '
            ) : (
              <>
                <p style={{ marginTop: '20px' }}>
                  Ready to move forward? Click here to submit your IRDA
                  Certificate!
                </p>
                <div
                  className="pos-wwu-button pos"
                  onClick={() => navigate('/review')}
                >
                  Submit IRDA Certificate
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
      )}
    </>
  );
};

export default PosCard;
