import React, { useState } from 'react';
import axios from 'axios';

const CustomerListItem = ({ customer, onSelect }) => (
  <li className="customer-item">
    <div>
      <strong>ID:</strong> {customer.customerId} <br />
      <strong>Name:</strong> {customer.fullName} <br />
      <strong>Phone:</strong> {customer.phone} <br />
      <strong>Email:</strong> {customer.email}
    </div>
    <button className="select-btn" onClick={() => onSelect(customer)}>
      Select
    </button>
  </li>
);

const CustomerPopup = ({ customers, onSelect, onClose, totalCustomers }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState(customers);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    customerId: `SCU${totalCustomers + 1}`,
    fullName: '',
    email: '',
    phone: '',
    pan: '',
  });

  // Handle form input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customers`,
        formData
      );
      if (response.status === 200) {
        console.log('Customer added successfully');
        setShowForm(false); // Close the form
        setFormData({ fullName: '', email: '', phone: '', pan: '' });

        // Fetch the updated list of customers
        setFilteredCustomers([...filteredCustomers, response.data.data]);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    // Filter the customers based on the search term
    const filtered = customers.filter((customer) =>
      customer.fullName.toLowerCase().includes(term)
    );
    setFilteredCustomers(filtered);
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-header">
          <h3>Select a Customer</h3>
          <button onClick={onClose} className="close-btn">
            ✖
          </button>
          <input
            type="text"
            placeholder="Search customer..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input-cust-info"
          />
        </div>
        <div className="popup-body">
          {filteredCustomers.length > 0 ? (
            <ul className="customer-list">
              {filteredCustomers.map((customer) => (
                <CustomerListItem
                  key={customer.id}
                  customer={customer}
                  onSelect={onSelect}
                />
              ))}
            </ul>
          ) : (
            <>
              <p>No customers found.</p>
              <button
                onClick={() => setShowForm(!showForm)}
                className="add-customer-btn"
              >
                Add New Customer
              </button>
            </>
          )}

          {showForm && (
            <div className="customer-form">
              <h3>Add New Customer</h3>
              <form>
                <label>
                  Full Name:
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <label>
                  Email:
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <label>
                  Phone:
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <label>
                  PAN:
                  <input
                    type="text"
                    name="pan"
                    value={formData.pan}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <button
                  type="submit"
                  onClick={(event) => handleFormSubmit(event)}
                >
                  Submit
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerPopup;
