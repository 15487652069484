const moment = require('moment');
const placeholder = 'https://placehold.co/600x400?text=S'

const RmModelData = ({posInfo,modalCloses }) => {
    return (
      <div>
        <div id="myModal" className="modalPos">
          <div className="modal-content main-model-data">
            <div className="close-pos-model" onClick={modalCloses}>
              &times;
            </div>
            <div className="inside_box">
              <div className="profile_div">
                <img src="abcd" className="profile_photo" />
              </div>
              <div className="content_popup">
                <div className="popup_head">
                  <h3 className="heading">RM Details :</h3>
                  <h4>Edit Role</h4>
                </div>
                <div className="rm_basic">
                  <div className="rm_right">
                    <div>
                      <h3>
                        Member Since : <span>20/01/2020</span>
                      </h3>
                      <h3>
                        RM ID :<span>#321431</span>
                      </h3>
                    </div>
                    <div>
                      <h3>
                        RM Name : <span>{posInfo?.informationRM?.name}</span>
                      </h3>
                      <h3>
                        RM Email :<span>example@gmail.com</span>
                      </h3>
                    </div>
                    <div>
                      <h3>
                        RM Mobile No. :{' '}
                        <span>+91 {posInfo?.informationRM?.phone}</span>
                      </h3>
                      <h3>
                        Sr. RM/No. :<span>Nitin Kumar B/+91 - 98765432210</span>
                      </h3>
                    </div>
                    <div>
                      <h3>
                        House No. : <span>Number 419/B</span>
                      </h3>
                      <h3>
                        Street :<span>Nelgulli After Sonamahalli Gate</span>
                      </h3>
                    </div>
                    <div>
                      <h3>
                        Town : <span>Bengaluru</span>
                      </h3>
                      <h3>
                        District :
                        <span>{`${posInfo.district} (${posInfo.area})`}</span>
                      </h3>
                    </div>
                    <div>
                      <h3>
                        State : <span>{posInfo?.state}</span>
                      </h3>
                      <h3>
                        Pincode :<span>{posInfo?.pincode}</span>
                      </h3>
                    </div>
                    <div>
                      <h3>
                        Area : <span>{posInfo.area}</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <hr className="popUp_line" />

                <h3 className="heading">Other Details :</h3>
                <div className="rm_basic">
                  <div className="rm_right">
                    <div>
                      <h3>
                        Gender :<span>{posInfo?.gender}</span>
                      </h3>
                      <h3>
                        D.O.B :
                        <span>{moment(posInfo?.dob).format('DD/MM/YYYY')}</span>
                      </h3>
                    </div>
                    <div>
                      <h3>
                        Pan Card :<span>{posInfo?.documents?.pancard}</span>
                      </h3>
                      <h3>
                        Basic Quilification :
                        <span>{posInfo?.qualification}</span>
                      </h3>
                    </div>
                    <div>
                      <h3>
                        IRDA Number :
                        <span>
                          {posInfo?.irdaNumber
                            ? posInfo?.irdaNumber
                            : 'Not Available'}
                        </span>
                      </h3>
                      <h3 style={{ cursor: 'pointer' }}>
                        IRDA Document :{' '}
                        {posInfo?.irdaImg ? (
                          <a href={posInfo.irdaImg} download>
                            Download
                          </a>
                        ) : (
                          <span>No document available</span>
                        )}
                      </h3>
                    </div>
                  </div>
                </div>
                <hr className="popUp_line" />

                <h3 className="heading">Policy Details :</h3>
                <div className="rm_basic">
                  <div className="rm_right">
                    <div>
                      <h3>
                        Policy Sold :<span>50</span>
                      </h3>
                      <h3>
                        Type of policy sold :
                        <span>Health, Life, Motar(Bike & Car)</span>
                      </h3>
                    </div>
                    <div>
                      <h3>
                        Last Policy Sold :<span>23/08/2022</span>
                      </h3>
                      <h3>
                        Status :<span>Active</span>
                      </h3>
                    </div>
                    <div>
                      <h3>
                        Business last year (2021) :<span>₹ 16,32,018</span>
                      </h3>
                      <h3>
                        Business this year (2022) :<span>₹ 32,012</span>
                      </h3>
                    </div>
                    <div>
                      <h3>
                        KYC Status :<span>Approved</span>
                      </h3>
                      <h3>
                        No. of POS :<span>34</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <hr className="popUp_line" />

                <h3 className="heading">Document Details :</h3>
                <div className="rm_basic">
                  <div className="rm_details">
                    <div className="details_front">
                      <div>
                        <h3>Aadhar card Front:</h3>
                        <img
                          src={posInfo?.documents?.aadharFront || placeholder}
                          alt="Aadhar Front"
                        />
                      </div>

                      <div>
                        <h3>Pan card Front :</h3>
                        <img src={posInfo?.documents?.pancard} />
                      </div>
                      <div>
                        <h3>Education Quilification Proof :</h3>
                        <img src={posInfo?.documents?.education} />
                      </div>
                    </div>

                    <div className="details_back">
                      <div>
                        <h3>Aadhar card back :</h3>
                        <img src={posInfo?.documents?.aadharBack} />
                      </div>
                      <div>
                        <h3> </h3>
                      </div>
                      {/* <div><h3>Pan card back :</h3><img src={posInfo?.documents?.pancard} /></div> */}
                      <div>
                        <h3>Signature Proof :</h3>
                        <img src={posInfo?.documents?.signature} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default RmModelData