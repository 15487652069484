import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './feadback.css';

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/feedback`
        );
        setTestimonials(response.data.data); // Adjust the data structure based on your API response
        setLoading(false);
      } catch (err) {
        console.error('Error fetching testimonials:', err);
        setError('Failed to load testimonials. Please try again later.');
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  const generateDummyImage = (name) => {
    const firstLetter = name ? name.charAt(0).toUpperCase() : '?';
    const bgColor = '#' + Math.floor(Math.random() * 16777215).toString(16); 
    return `https://via.placeholder.com/100/${bgColor.slice(
      1
    )}/ffffff?text=${firstLetter}`;
  };

  if (loading) {
    return <div className="testimonials">Loading testimonials...</div>;
  }

  if (error) {
    return <div className="testimonials">{error}</div>;
  }

  return (
    <div className="testimonials">
      <h1>KNOW WHAT OUR CUSTOMERS HAVE TO SAY</h1>
      <p>
        Wondering how Simpliinsure has helped others? Read the inspiring stories
        from people just like you who have trusted us to protect their future.
        These real-life testimonials showcase the ease, reliability, and
        benefits of being part of the Simpliinsure family. Get a closer look at
        how our policies have supported customers in every stage of life.
      </p>

      {testimonials &&
      testimonials.length > 0 &&
      testimonials.some((testimonial) => testimonial.isApproved) ? (
        testimonials
          .filter((testimonial) => testimonial.isApproved)
          .map((testimonial, index) => (
            <div key={index} className="testimonial">
              <img
                src={generateDummyImage(testimonial.firstName)}
                alt="Customer"
                className="testimonial-image"
              />
              <div className="testimonial-content">
                <h2>
                  {testimonial.firstName} {testimonial.lastName}{' '}
                  <span className="stars">
                    {'★'.repeat(testimonial.rating || 0)}
                  </span>
                </h2>
                <p>{testimonial.review}</p>
                <p className="date">
                  {new Date(testimonial.createdAt).toLocaleDateString()}
                </p>
              </div>
            </div>
          ))
      ) : (
        <div className="testimonials noFeedBack">No Feedback available...</div>
      )}
    </div>
  );
};

export default Testimonials;
