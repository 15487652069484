import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import './customerDetail.css';
import { GetCustomerFromList } from '../../../../Redux/DashboardRedux';

export default function PolicyData({ customerData }) {
    const dispatch = useDispatch();
    const [policyData, setPolicyData] = useState(null);
  
      const { customerDataFromList } = useSelector(
        (state) => state.dashboardRedux
      );
        console.log(
          '🚀 ~ PolicyData ~ customerDataFromList:',
          customerDataFromList
        );

    useEffect(() => {
      if (customerData) {
        console.log('🚀 ~ useEffect ~ customerData:', customerData);
        dispatch(GetCustomerFromList({ customerId: customerData.customerId }));
      }
    }, [customerData.customerId, dispatch]);

  return (
    <div className="policy-table-section">
      <h3>Policy Details</h3>
      <table className="policy-table">
        <thead>
          <tr>
            <th>Customer ID</th>
            <th>Insurer</th>
            <th>Policy Status</th>
            <th>Policy Type</th>
            <th>Policy Number</th>
            <th>Expiry Date</th>
            <th>Customer</th>
            <th>Mobile No.</th>
            <th>Email Id</th>
            <th>RM</th>
            <th>Amount</th>
            <th>Policy</th>
          </tr>
        </thead>
        <tbody>
          {customerDataFromList?.data[0]?.policyData?.map((policy) => (
            <tr key={policy.customerID}>
              <td>{policy.customerID}</td>
              <td>{policy.policyHolderName}</td>
              <td>{policy.policyStatus}</td>
              <td>{policy.newPolicyData.policyType}</td>
              <td>{policy.policyNumber}</td>
              <td>{policy.quickQuote.policyEndDate}</td>
              <td>{policy.fullName}</td>
              <td>{policy.ownerDetails.mobileNo}</td>
              <td>{policy.ownerDetails.email}</td>
              <td>{policy.rmName}</td>
              <td>{policy.price}</td>
              <td>
                <a href={policy.quotationURL} download>
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
