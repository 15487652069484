import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetCall, apiPostCall, apiGETCall1, apiPutCall } from '../utilities/site-apis';

const initialState = {
  isFetching: false,
  error: null,
  isOptSend: false,
  name: null,
  phone: null,
  user: null,
  token: null,
  isSignUp: false,
  otp: null,
  errorMessage: '',
  isVerified: false,
  userDetails: {},
  Authorization: false,
  usersList: [],
  rmList:[],
  roles: [],
  nonPos: {},
  contactLead : {},
  productLead : {},
  userProfileData: {},
  posUser:[],
  careerApply: {},
  newsLatter: {}
}

export const register = createAsyncThunk(
  'auth/register',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(`/user/checkUser`, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    console.log("🚀 ~ file: AuthRedux.jsx:31 ~ response.data:", response.data)
    return response.data
  }
)

export const findUser = createAsyncThunk(
  'auth/findUser',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(`/user/findUser`, params)
    console.log("🚀 ~ file: AuthRedux.jsx:39 ~ response:", response)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)

export const findPosUser = createAsyncThunk(
  'auth/findUser',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(`/user/findUser`, params)
    console.log("🚀 ~ file: AuthRedux.jsx:39 ~ response:", response)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)

export const updateUserRoles = createAsyncThunk(
  'user/roles',
  async (params, { rejectWithValue }) => {
    console.log("params are", params);
    const response = await apiPutCall(`user/role/` + params.userId, params)
    console.log("response", response.data);
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)

export const updateAuthorization = createAsyncThunk(
  'auth/Authorization',
  async (params, { rejectWithValue }) => {
    return params
  }
)
export const resetRedux = createAsyncThunk(
  'auth/resetRedux',
  async (params, { rejectWithValue }) => {
    return 0;
  }
)

export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async (params, { rejectWithValue }) => {
    console.log("params is -> ", params.userId);
    const response = await apiPutCall(`user/` + params.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)

export const getUserProfile = createAsyncThunk(
  'auth/getUserProfile',
  async (params, { rejectWithValue }) => {
    console.log("params is -> ", params.userId);
    const response = await apiGETCall1(`user/` + params.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)



export const resendOTP = createAsyncThunk(
  'auth/otpReSend',
  async (params, { rejectWithValue }) => {
    const response = await apiGetCall(`/user/resendCode`, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)

export const GetUserList = createAsyncThunk(
  'auth/GetUserList',
  async (params, { rejectWithValue }) => {
    const response = await apiGETCall1(`/user/list`, params)
    // console.log("🚀 ~ file: AuthRedux.jsx ~ line 61 ~ response", response)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)
export const GetRmList = createAsyncThunk(
  'auth/GetRmList',
  async (params, { rejectWithValue }) => {
    const response = await apiGETCall1(`/user/rmList`, params)
    // console.log("🚀 ~ file: AuthRedux.jsx ~ line 61 ~ response", response)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)

export const updateUserDetails = createAsyncThunk(
  'auth/updateUserDetails',
  async (params, { rejectWithValue }) => {
    return params
  }
)

// export const verifyUser1 = createAsyncThunk(
//   'auth/verifyUser1',
//   async (params, { rejectWithValue }) => {
//     // console.log("params are ----->>", params.data)
//     localStorage.setItem('Authorization', params?.data?.Authorization);
//     localStorage.setItem('userDetails', JSON.stringify(params?.data));
//     return params
//   }
// )

export const verifyUser1 = createAsyncThunk(
  'auth/verifyUser1',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = params;
      
      if (data && data.Authorization) {
        localStorage.setItem('Authorization', data.Authorization);
        localStorage.setItem('userDetails', JSON.stringify(data));
        return params;
      } else {
        console.error('Invalid data format in verifyUser1 thunk:', data);
        throw new Error('Invalid data format');
      }
    } catch (error) {
      console.error('Error in verifyUser1 thunk:', error);
      throw error;
    }
  }
);

// export const verifyUser = createAsyncThunk(
//   'auth/verifyUser',
//   async (params, { rejectWithValue }) => {
//     console.log("params are", JSON.stringify(params));
//     const response = await apiPostCall(`/user/verifyUser`, params)
//     if (response.data.status === 'error') {
//       return rejectWithValue(response.data)
//     }
//     console.log("verify user data is ---->>>>", JSON.stringify(response?.data?.data));
//     localStorage.setItem('Authorization', response?.data?.data?.Authorization);
//     localStorage.setItem('userDetails', JSON.stringify(response?.data?.data));
//     return response.data
//   }
// )

export const verifyUser = createAsyncThunk(
  'auth/verifyUser',
  async (params, { rejectWithValue }) => {
    try {
      const response = await apiPostCall(`/user/verifyUser`, params);

      if (response.data.status === 'error') {
        return rejectWithValue(response.data);
      }

      const userDetails = response?.data?.data;
      if (!userDetails) {
        console.error('User details are null or undefined');
      } else {
        console.log("verify user data is ---->>>>", JSON.stringify(userDetails));
        localStorage.setItem('Authorization', userDetails.Authorization);
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
      }

      return response.data;
    } catch (error) {
      console.error('Error in verifyUser thunk:', error);
      throw error; // Re-throw the error to be handled in the component or through other middleware
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/reset-password',
  async (params, { rejectWithValue }) => {
    const response = await apiPutCall(`/user/reset-password/${params?.userId}`, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)

export const ContactLead = createAsyncThunk(
  'auth/ContactLead',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(`/contactLead/lead-create`, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)
export const ProductLead = createAsyncThunk(
  'auth/ProductLead',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(`/productLead/lead-create`, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)
export const careerApply = createAsyncThunk(
  'auth/careerApply',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(`/career/apply`, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)
export const newsLatter = createAsyncThunk(
  'auth/newsLatter',
  async (params, { rejectWithValue }) => {
    const response = await apiPostCall(`/newsletter/create`, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)



export const counterSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginRedirectUrl: (state, action) => {
      state.loginRedirectUrl = action.payload
    },
    setIsSignUp: (state, action) => {
      state.isSignUp = action.payload
    },
    logout: (state, action) => {
      state.user = null
      state.token = null
    },
  },
  extraReducers: {

    [register.pending]: (state, action) => {
      state.isFetching = true
      state.error = null
      state.isOptSend = false
    },
    [register.rejected]: (state, action) => {
      if (action.payload.message == 'OTP already sent, please check else resend the otp') {
        state.isOptSend = true
        state.isFetching = false
        state.name = action.payload.name
        state.phone = action.payload.phone
      } else if (action.payload.message == 'User Not Exist !! Please Signup') {
        state.isSignUp = true
        state.isFetching = false
        state.name = action.payload.name
        state.phone = action.payload.phone
      } else if (action.payload.message == 'User already exist. Please signin') {
        state.isSignUp = false
        state.isFetching = false
        state.name = action.payload.name
        state.phone = action.payload.phone
      } else {
        state.isFetching = false
        state.error = action.payload.message
      }
    },
    [register.fulfilled]: (state, action) => {
      if (action.payload.message == 'User Not Exist, Please Signup !!' || action.payload.message == 'User Already Exist, Please login') {
        state.error = true
        state.errorMessage = action.payload.message
        state.isFetching = false
      }
      else if (action.payload.message == 'OTP already sent, Please Verify!') {
        alert(action.payload.message)
        state.isFetching = false
        state.error = null
        state.isOptSend = true
      }
      else {
        let result = action.payload.message.substr(4, 4);
        state.isFetching = false
        state.error = null
        state.otp = result
        state.isOptSend = true
        state.name = action.payload.name
        state.phone = action.payload.phone
      }
    },

    [updateAuthorization.fulfilled]: (state, action) => {
      state.Authorization = action.payload
    },
    [verifyUser.pending]: (state, action) => {
      state.isFetching = true
    },
    [verifyUser.rejected]: (state, action) => {
      state.isFetching = false
      if (action.payload.message == 'Invalid OTP!') {
        state.error = true
        state.errorMessage = action.payload.message
      }
    },
    [verifyUser.fulfilled]: (state, action) => {
      if (action.payload.message == 'Success') {
        // localStorage.setItem('userDetails', JSON.stringify(action.payload.data));
        state.isVerified = true
        state.userDetails = action.payload.data
        state.isOptSend = false
        state.Authorization = true
      }
      if (action.payload.message.includes("sent successfully! Valid for 10mins.")) {
        let result = action.payload.message.substr(4, 4);
        state.isFetching = false
        state.error = null
        state.otp = result
        state.isOptSend = true
        state.name = action.payload.name
        state.phone = action.payload.phone
      }
      state.isFetching = false
    },
    [verifyUser1.pending]: (state, action) => {
      state.isFetching = true
    },
    [verifyUser1.rejected]: (state, action) => {
      state.isFetching = false
      if (action.payload.message == 'Invalid OTP!') {
        state.error = true
        state.errorMessage = action.payload.message
      }
    },
    [verifyUser1.fulfilled]: (state, action) => {
      // console.log("action.payload is", action.payload);
      if (action.payload.message == 'Success') {
        // localStorage.setItem('userDetails', JSON.stringify(action.payload.data));
        state.isVerified = true
        state.userDetails = action.payload.data
        state.isOptSend = false
        state.Authorization = true
      }
      if (action.payload.message.includes("sent successfully! Valid for 10mins.")) {
        let result = action.payload.message.substr(4, 4);
        state.isFetching = false
        state.error = null
        state.otp = result
        state.isOptSend = true
        state.name = action.payload.name
        state.phone = action.payload.phone
      }
      state.isFetching = false
    },

    [resetPassword.pending]: (state, action) => {
      state.isFetching = true
    },
    [resetPassword.rejected]: (state, action) => {
      state.isFetching = false
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.isFetching = false
    },


    [ContactLead.pending]: (state, action) => {
      state.isFetching = true
    },
    [ContactLead.rejected]: (state, action) => {
      state.isFetching = false
    },
    [ContactLead.fulfilled]: (state, action) => {
      state.isFetching = false
      state.error = null
      state.contactLead = action.payload
    },

    [ProductLead.pending]: (state, action) => {
      state.isFetching = true
    },
    [ProductLead.rejected]: (state, action) => {
      state.isFetching = false
    },
    [ProductLead.fulfilled]: (state, action) => {
      state.isFetching = false
      state.error = null
      state.productLead = action.payload
    },

    [careerApply.pending]: (state, action) => {
      state.isFetching = true
    },
    [careerApply.rejected]: (state, action) => {
      state.isFetching = false
    },
    [careerApply.fulfilled]: (state, action) => {
      state.isFetching = false
      state.error = null
      state.careerApply = action.payload
    },

    [newsLatter.pending]: (state, action) => {
      state.isFetching = true
    },
    [newsLatter.rejected]: (state, action) => {
      state.isFetching = false
    },
    [newsLatter.fulfilled]: (state, action) => {
      state.isFetching = false
      state.error = null
      state.newsLatter = action.payload
    },



    [GetUserList.pending]: (state, action) => {
      state.isFetching = true
    },
    [GetUserList.rejected]: (state, action) => {
      state.isFetching = false
    },
    [GetUserList.fulfilled]: (state, action) => {
      // console.log("🚀 ~ file: AuthRedux.jsx ~ line 195 ~ action", action.payload)
      state.isFetching = false
      state.usersList = action.payload.data
    },
    [GetRmList.pending]: (state, action) => {
      state.isFetching = true
    },
    [GetRmList.rejected]: (state, action) => {
      state.isFetching = false
    },
    [GetRmList.fulfilled]: (state, action) => {
      // console.log("🚀 ~ file: AuthRedux.jsx ~ line 195 ~ action", action.payload)
      state.isFetching = false
      state.rmList = action.payload.data
    },

    getUserProfile: (state, action) => {
      state.isFetching = true
    },
    [getUserProfile.rejected]: (state, action) => {
      state.isFetching = false
    },
    [getUserProfile.fulfilled]: (state, action) => {
      state.isFetching = false
      state.userProfileData = action.payload
    },

    [findUser.fulfilled]: (state, action) => {
      // console.log("🚀 ~ file: AuthRedux.jsx ~ line 195 ~ action", action.payload)
      state.isFetching = false
      state.nonPos = action.payload
    },
    [updateProfile.pending]: (state, action) => {
      state.isFetching = true
    },
    [updateProfile.rejected]: (state, action) => {
      state.isFetching = false
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.isFetching = false
      if (action.payload && action.payload.data) {
          state.userDetails = action.payload.data;
          localStorage.setItem('userDetails', JSON.stringify(action.payload.data));
          localStorage.setItem('Authorization', action.payload.data.token[0]);
          // if(action.payload.token)
          // localStorage.Authorization('userDetails', JSON.stringify(action.payload.token));
        }
    },

    [updateUserRoles.pending]: (state, action) => {
      state.isFetching = true
    },
    [updateUserRoles.rejected]: (state, action) => {
      state.isFetching = false
    },
    [updateUserRoles.fulfilled]: (state, action) => {
      state.isFetching = false
      state.error = null
      state.roles = action.payload.data.roles
      console.log("payload is", action.payload.data.roles);
      // alert('Role updated successfully.')
    },

    [resendOTP.fulfilled]: (state, action) => {
      if (action.payload.message.includes("sent successfully! Valid for 10mins.")) {
        let result = action.payload.message.substr(4, 4);
        state.isFetching = false
        state.error = null
        state.otp = result
        state.isOptSend = true
        state.name = action.payload.name
        state.phone = action.payload.phone
      }
      state.isFetching = false
    },
    [updateUserDetails.fulfilled]: (state, action) => {
      state.userDetails = action.payload
    },
    [resetRedux.fulfilled]: (state, action) => {
      state.isFetching = false
      state.error = null
      state.isOptSend = false
      state.name = null
      state.phone = null
      state.user = null
      state.token = null
      state.isSignUp = false
      state.otp = null
      state.errorMessage = ''
      state.isVerified = false
      state.userDetails = {}
      state.Authorization = false
    },
  }

})

export const { logout, setLoginRedirectUrl, setIsSignUp } = counterSlice.actions
export default counterSlice.reducer