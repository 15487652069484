// ContactUsPage.js

import React from 'react';
import { FaWhatsapp,FaLinkedin,FaYoutube,FaFacebook,FaInstagram } from 'react-icons/fa'; // Import react-icons for icons
import { FaXTwitter } from "react-icons/fa6";
import { MdConnectWithoutContact } from "react-icons/md";
import './contactPage.css';
import FooterNew from '../../components/Footer/FooterNew';
import BlogCard from '../../components/blog-card/blog';
import Hero from '../../components/Hero_Banner/hero';
import Quick from '../../components/new_quick_links/quick';
import contactBanner from '../../assets/contact-banner.png';
import ContactForm from '../new-contact-us/new-contact';
import Map from '../../components/map';

const ContactUsPage = () => {
  return (
    <div>
      <Hero
        data={'#'}
        image={contactBanner}
        heading={'Contact Information'}
        subHeading={'Get in Touch for Expert Assistance.Were Here to Help You'}
        btn={true}
        icon={<MdConnectWithoutContact size={100} />}
      />
      <Quick heading={'Contact Us'} />
      <div className="contact-us-page">
        <p style={{ fontSize: '25px', fontWeight: 'bold', margin: '20px 0px' }}>
          Contact Information
        </p>
        {/* Section 1: Contact Information */}
        <section className="contact-info-section">
          <div className="left-part">
            <div className="right-contact-detail">
              <h2 style={{ marginTop: '20px' }}>Address</h2>
              <div className="beng_brach_data">
                <p>Bengaluru: Corporate Office (Registered Address): </p>
                <p>Simpliinsure.com (A VGIB Brand): </p>
                <p>
                  <a
                    style={{ color: '#000000' }}
                    href="https://maps.app.goo.gl/xHdMVN7wd2YaxeVn6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    No. 91/1, 1st Floor, Above ICICI Bank, Infantry Road,
                    Bangalore - 560001, Karnataka, India
                  </a>
                </p>

                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <p>Phone Number:</p>
                  <p>
                    <a
                      style={{ color: '#000000' }}
                      href="tel:+919513355661"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +91 95133 55661/62/63/64
                    </a>
                    ;{' '}
                    <a
                      style={{ color: '#000000' }}
                      href="tel:+919980850054"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +91 99808 50054
                    </a>
                  </p>
                </div>
                <div>
                  <p>Email ID:</p>
                  <p>
                    <a
                      style={{ color: '#000000' }}
                      href="mailto:info@simpliinsure.com"
                    >
                      info@simpliinsure.com
                    </a>
                  </p>
                </div>
              </div>

              <div className="nagpur_brach_data">
                <p>Nagpur( Branch Office) </p>
                <p>Simpliinsure.com (A VGIB Brand): </p>
                <p>
                  No. 32, Income Tax Colony, Pratap Nagar, Nagpur, Maharashtra,
                  India 440022
                </p>

                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <p>Phone Number:</p>
                  <p>
                    <a
                      style={{ color: '#000000' }}
                      href="tel:+918956516635"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +91 8956516635
                    </a>
                    ,{' '}
                    <a
                      style={{ color: '#000000' }}
                      href="tel:+917709940425"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +91 7709940425
                    </a>
                  </p>
                </div>
                <div>
                  <p>Email ID:</p>
                  <p>
                    <a
                      style={{ color: '#000000' }}
                      href="mailto:info@simpliinsure.com"
                    >
                      info@simpliinsure.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="right-part">
            <div className="contact-form-detail">
              <ContactForm title={'Contact Us'} titleSize={'30px'} />
            </div>
          </div>
        </section>

        {/* Section 2: Map */}
        <section className="map-section">
          <div className="map-section">
            <Map />
          </div>
        </section>

        {/* Section 3: General Enquiry */}
        <section className="general-enquiry">
          <p className="cs_gi_sm">CUSTOMER SUPPORT</p>
          <p>
            Our dedicated support team is available Monday to Saturday, 9:30 am
            to 6:30 pm, to assist you with any questions or concerns you may
            have.
          </p>
          <p>
            Phone :{' '}
            <a
              style={{ color: '#000000' }}
              href="tel:+919980850054"
              target="_blank"
              rel="noreferrer"
            >
              +91 99808 50054{' '}
            </a>
          </p>
          <p>
            Email :{' '}
            <a style={{ color: '#000000' }} href="mailto:info@simpliinsure.com">
              info@simpliinsure.com
            </a>
          </p>
        </section>

        {/* Section 4: Customer Support */}
        <section className="customer-support">
          <p className="cs_gi_sm">GENERAL INQUIRIES</p>
          <p>
            For general inquiries, please reach out to us at
            info@simpliinsure.com
          </p>
        </section>
        <section className="customer-support">
          <p className="cs_gi_sm">FEEDBACK</p>
          <p>
            We appreciate your feedback and would love to hear from you! Share
            your thoughts, suggestions, or testimonials with us at
            info@simpliinsure.com
          </p>
        </section>

        {/* Section 5: Social Media */}
        <section className="social-media">
          <p className="cs_gi_sm">Follow us on social media</p>
          <p>
            Stay updated with the latest in business insurance trends and advice
            by following Simpli Insure on social media, your gateway to informed
            protection for your ventures in India.
          </p>

          <div className="social-media-icons">
            <a
              href="https://www.facebook.com/SimpliInsureOfficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook size={40} style={{ color: '#1877f2' }} />
            </a>
            <a
              href="http://www.twitter.com/SimpliInsure"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaXTwitter size={40} style={{ color: '#000000' }} />
            </a>
            <a
              href="http://www.instagram.com/simpliinsureofficial/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                style={{
                  background:
                    'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d, #f56040, #f77737, #fcaf45, #ffdc80)',
                  borderRadius: '50%',
                  padding: '6px 5px 0px 5px',
                }}
              >
                <FaInstagram size={28} style={{ color: '#fff' }} />
              </div>
            </a>
            <a
              href="https://wa.me/+919606045764?text=Hi%20SimpliInsure,%20I%20have%20a%20query%20regarding%20my%20insurance%20policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* Assuming there is no specific WhatsApp icon, you can use a generic one */}
              <FaWhatsapp size={40} style={{ color: '#25d366' }} />
            </a>
            <a
              href="http://www.linkedin.com/company/simpliinsure/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin size={40} style={{ color: '#0077b5' }} />
            </a>
            <a
              href="http://www.youtube.com/@SimpliInsureOfficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube size={40} style={{ color: '#ff0000' }} />
            </a>
          </div>
        </section>
      </div>
      <div className="blog_section">
        <h2 className="learnMore">LEARN MORE</h2>
        {/* <div className="blog_container"> */}
        <BlogCard />
        {/* </div> */}
      </div>
      <div style={{ padding: '40px' }}></div>
      <FooterNew />
    </div>
  );
};

export default ContactUsPage;
