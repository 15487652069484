import { useEffect, useMemo, useState } from "react"
import Footer from "../../components/Footer/FooterNew"
import { ContinueBtn, Description, ErrArrow, ErrorContainer, ErrorIcon, ErrorMsgContainer, FloatingTitle, FormHeading, HDiv, HDivApart, InputContainer, LeftDiv, LeftSubDiv, LoginPassword, MainContainer, Resend, RightDiv, SubmittedContainer, SubmittedIcon, SuccessIcon } from "./styles"
import ErrorIconImg from '../../assets/brand-new-car/exclamation.svg'
import SuccessIconImg from '../../assets/commons/success.svg'
import SubmittedGif from '../../assets/commons/submitted.gif'
import { useSelector, useDispatch } from "react-redux";
import { findUser, register, resendOTP, updateProfile, verifyUser, verifyUser1 } from "../../Redux/AuthRedux";
import { Oval } from 'react-loader-spinner';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Carousal from "./Carousal"
import { validation } from "../../utilities/validations"
import './signup.css'
import { IoEyeSharp } from "react-icons/io5";
import { IoEyeOffSharp } from "react-icons/io5";
import { only10DigitAndNumber } from "../../utilities/only10DigitAndNum"
import { Link } from "react-router-dom";
import {axiosPOSTCall1, axiosGETCall1, axiosGETCall12 } from "../../utilities/utils/apiPost";
import { PosApprovedData, setPosData } from "../../Redux/posRedux";
import { apiGetCall } from '../../utilities/site-apis.jsx';
import { useCallback } from 'react';



export const SigninSignup = () => {

  const dispatch = useDispatch();
  const { state } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const name = useSelector((state) => state);
  const [OTPState, setOTPState] = useState();

  // for handle POS and normal user
  const searchParams = new URLSearchParams(location.search);
  const sourcePage = searchParams.get('sourcePage') || 'header';

  const [posIdScanner, setPosIdScanner] = useState(
    searchParams.get('posId') || ''
  );
  const [isScanner, setIsScanner] = useState(
    searchParams.get('isScanner') === 'true'
  );

  // Fetch data
  const [responseFinal, setResponseFinal] = useState(null); // State for response
  const [hasDispatched, setHasDispatched] = useState(false); // To prevent multiple dispatches

  const fetchData = useCallback(async () => {
    try {
      const response = await apiGetCall(`/pospInfo/find`, {
        _id: posIdScanner,
        isScanner: isScanner,
      });
      setResponseFinal(response); // Save response to state
      setHasDispatched(true); // Mark as dispatched
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [posIdScanner, isScanner]); // Depend on the state variables

  useEffect(() => {
    if (isScanner && !hasDispatched) {
      fetchData(); // Fetch data only if not already dispatched
    }
  }, [fetchData, isScanner,  hasDispatched]); // Include dependencies

  // const [posData, setPosData] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (isScanner && !hasDispatched) {
  //       try {
  //         const response = await apiGETCall1(`/pospInfo/find`, {
  //           _id: posIdScanner,
  //           isScanner: isScanner,
  //         });
  //         console.log("🚀 ~ fetchData ~ response finallll:", response)
  //         setPosData(response.data);
  //         setHasDispatched(true);
  //       } catch (error) {
  //         console.error('Error fetching data:', error);
  //       }
  //     }
  //   };

  //   fetchData(); // Call the async function inside the effect
  // }, [isScanner, posIdScanner, hasDispatched]);

  // const posApproved = useSelector((state) => state.posRedux.posApproved);
  // console.log('🚀 ~ SigninSignup ~ posApproved -99999:', posApproved);

  // if (posIdScanner && isScanner) {
  //   let obj = {
  //     _id: posIdScanner,
  //     isScanner: isScanner,
  //   };
  //   dispatch(PosApprovedData(obj));
  // }

  // if (posIdScanner && isScanner) {
  //   localStorage.setItem('posIdScanner', posIdScanner);
  // }
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  useEffect(() => {
    if (location.search.substring(0, 11) == '?isPos=true') {
      setForm('phone');
    }
    // if (name.auth.error) {
    //     alert(name.auth.errorMessage)
    //     return
    // }
    else if (name.auth.isVerified) {
      // setOtpSuccess(true)
      if (state?.hasOwnProperty('item')) {
        setTimeout(() => {
          var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));
          navigate('/car-insurance/payment/3?enquiryId=' + enquiryId, {
            replace: true,
            state: state?.item,
            totalPrice: state?.item?.price,
          });
        }, 3000);
      }
      if (state?.hasOwnProperty('item')) {
        setTimeout(() => {
          var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));
          navigate('/car-insurance/payment/3?enquiryId=' + enquiryId, {
            replace: true,
            state: state?.item,
            totalPrice: state?.item?.price,
          });
        }, 3000);
      }
    }
  }, [name]);

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const [number, setNumber] = useState('');
  // const [form, setForm] = useState(state?.hasOwnProperty('item') ? null : 'otp')
  const [form, setForm] = useState(
    state?.hasOwnProperty('item') ? 'phone' : 'password-prompt'
  );
  const [CheckBoxState, setCheckBoxState] = useState('password');
  const [credentials, setCredentials] = useState({
    num: '',
    name: '',
    email: '',
  });
  const [psswd, setPsswd] = useState({
    password: '',
    cPassword: '',
  });
  const [otp, setOtp] = useState('');
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState('');
  const [timer, setTimer] = useState(30);
  const [otpSuccess, setOtpSuccess] = useState(false);

  // let posIdScanner = localStorage.getItem('posIdScanner');

  //  useEffect(() => {
  //    setIsScanner(() => {
  //      searchParams.get('isScanner');
  //    });
  //    setPosIdScanner(() => {
  //      searchParams.get('posId');
  //    });

  //    let objScanner = {
  //      _id: posIdScanner,
  //      isScanner: isScanner,
  //    };
  //    console.log('🚀 ~ useEffect ~ objScanner:', objScanner);

  //    if (posIdScanner && isScanner) {
  //      dispatch(PosApprovedData(objScanner));
  //    } else {
  //      console.log('Condition not met:', { posIdScanner, isScanner });
  //    }
  //  }, [isScanner, posIdScanner]);

  const ErrorPopup = (error) => {
    return (
      <ErrorContainer>
        <ErrorMsgContainer>{error.error}</ErrorMsgContainer>
        <ErrArrow />
        <ErrorIcon src={ErrorIconImg} />
      </ErrorContainer>
    );
  };

  useEffect(() => {
    if (otp.length < 4) {
      setTyping(true);
    } else if (otp.length === 4) {
      setTyping(false);
      setError(false);
    }
  }, [otp]);

  useEffect(() => {
    if (typing) {
      setError(false);
    }
  }, [number, credentials]);

  useEffect(() => {
    if (timer === 0) return;

    const startTimer = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(startTimer);
  }, [timer]);

  // Function for redirect to quote page after login
  const redirectToQuotePage = () => {
    const redirectURL = localStorage.getItem('redirectAfterLoginURL');

    if (redirectURL) {
      window.location.href = redirectURL;
      localStorage.removeItem('redirectAfterLoginURL');
      return;
    } else {
      navigate('/');
      window.location.reload();
      return;
    }
  };

  const submitFun = async () => {
    if (form !== 'pasword' && !validation(number, 'mob').status) {
      setError({ number: 'Please enter a valid mobile number.' });
      // alert('Please enter a valid mobile number.')
      return;
    }
    if (form === 'password-prompt') {
      if (!validation(number, 'mob').status)
        return setError({ number: 'Please enter a valid mobile number.' });
      const obj = {
        phone: number,
        countryCode: '+91',
      };
      if (CheckBoxState === 'password') {
        const res = await dispatch(findUser(obj));
        if (res?.payload?.status === 'Error')
          return setError({ number: 'User not found' });
        setForm('password-login');
      } else {
        const res = await dispatch(findUser(obj));
        if (res?.payload?.status === 'Error')
          return setError({ number: 'User not found' });
        dispatch(register(obj));
        setForm('otp');
      }
    } else if (form === 'password') {
      if (!validation(psswd.password, 'password').status) {
        return setError({ password: 'Invalid password format' });
      }
      if (psswd.password !== psswd.cPassword)
        return setError({ cPassword: 'Confirm password is incorrect' });

      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const obj = {
        userId: userDetails.userId,
        password: psswd.password,
        // posId: posIdScanner ? posIdScanner : "",
      };
      dispatch(updateProfile(obj));

      sourcePage === 'work-with-us' ? navigate('/partner') : navigate('/');
      window.location.reload();
    } else {
      if (!validation(credentials.name, 'name').status) {
        // alert('Please enter name.')
        setError({ name: 'Please enter valid name.' });
        return;
      }
      // if (!validation(credentials.num, 'mob').status) {
      //   setError({ number: 'Please valid number.' });
      // }
      if (!credentials.email.includes('@')) {
        // alert('Please enter a valid email.')
        setError({ email: 'Please enter a valid email.' });
        return;
      }
      let regEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(credentials.email)) {
        setError({ email: 'Please enter a valid email.' });
        return;
      }

      const obj = {
        phone: number,
        countryCode: '+91',
        email: credentials.email,
        fullName: credentials.name,
        posId: posIdScanner ? posIdScanner : "",
      };
     const res =await dispatch(register(obj));
     if(res?.payload?.message === 'User Already Exist, Please login') {
      setForm('password-prompt')
     }else{
      setForm('otp')
     }
    }
  };

  const ResendOTP = async () => {
    try {
      var obj = {
        phone: number,
      };
      // Assuming resendOTP returns a promise
      const response = await dispatch(resendOTP(obj));

      // Handle success if needed
      if (response?.payload?.status === 'Success') {
        alert('OTP Sent Successfully');
      } else if (response?.payload?.status === 'Error') {
        return alert(`Error: ${response?.payload?.message}`);
      }
    } catch (error) {
      // Show error alert on failure
      alert('Error: ' + error.data.message || 'Something went wrong!');
    }
  };

  const submitSignupOTP = async () => {
    if (!otp) {
      setError({ otp: 'Please enter OTP.' })
      return
    } else if (otp.length < 4) {
      setError({ otp: 'Please enter valid OTP.' });
      return;
    }
    const obj = {
      phone: number,
      code: otp,
      email: credentials.email,
      fullName: credentials.name,
      mode: 'otp',
      // posId: posIdScanner ? posIdScanner : "",
    };
    
    const res = await dispatch(verifyUser(obj));
    // if(res.payload?.status == "Success"){
    //         if(sourcePage == "work-with-us"){
    //             const posObj={
    //                 phone: number,
    //                 userId:res.payload.data.userId,
    //                 email: credentials.email,
    //                 fullName: credentials.name,
    //                 kycStatus:"Pending",
    //                 irdaStatus:"Pending",
    //             }
    //             axiosPOSTCall1("/pospInfo", posObj, (callBack) => {
    //                 if (callBack.status === "Success") {
    //                  console.log("pos created successfully.");

    //                 } else {
    //                   console.error("Error:", callBack.message);
    //                   return
    //                 }
    //               });
    //         }
    // }
    if (res.error) return setError({ otp: 'Invalid OTP' });
    if (obj.email && obj.fullName) {
      setForm('password');
    } else if (!state?.hasOwnProperty('item') && res?.payload?.data) {
      setOtpSuccess(true);
      setTimeout(() => {
        // refactor code of vivek - 19-01-2024
        if (
          res?.payload?.data?.roles.includes('admin') ||
          res?.payload?.data?.roles.includes('pos') ||
          res?.payload?.data?.roles.includes('employee')
        ) {
          navigate('/dashboard/agent');
          window.location.reload();
        }

        redirectToQuotePage();
        // navigate('/')
      }, 3000);
    } else {
      setError({ password: `${res?.payload?.message}` });
    }
  };

  const submitOTP = async () => {
    if (CheckBoxState === 'otp') {
      if (!otp) {
        setError({ otp: 'Please enter OTP.' });
        return;
      } else if (otp.length < 4) {
        setError({ otp: 'Please enter valid OTP.' });
        return;
      }
      const obj = {
        phone: number,
        code: otp,
        email: credentials.email,
        fullName: credentials.name,
        mode: 'otp',
        // posId: posIdScanner ? posIdScanner : "",
      };

      const res = await dispatch(verifyUser(obj));
      if (res.error) return setError({ otp: 'Invalid OTP' });
      if (obj.email && obj.fullName) {
        setForm('password');
      } else if (!state?.hasOwnProperty('item') && res?.payload?.data) {
        setOtpSuccess(true);
        if (
          res?.payload?.data?.roles.includes('user') &&
          res?.payload?.data?.posId
        ) {
          const posRes = await axiosGETCall12('/pospInfo/getPosInfo', {
            id: res?.payload?.data?.posId,
          });
          if (
            posRes.status === 'Success' &&
            ['pending', 'approved'].includes(posRes.data.examStatus)
          ) {
            navigate('/pos', { state: { examStatus: posRes.data.examStatus } });
            return;
          }
          if (
            posRes.status === 'Success' &&
            posRes.data.kycStatus == 'Pending'
          ) {
            navigate(`/partner?id=${posRes.data._id}`);
            return;
          }
        }
        setTimeout(() => {
          if (
            res?.payload?.data?.roles.includes('admin') ||
            res?.payload?.data?.roles.includes('pos') ||
            res?.payload?.data?.roles.includes('employee')
          ) {
            navigate('/dashboard/agent');
            window.location.reload();
          }
          // reload page
          sourcePage === 'work-with-us' ? navigate('/partner') : navigate('/');
          window.location.reload();
        }, 3000);
      } else {
        setError({ password: `${res?.payload?.message}` });
      }
    } else {
      if (!psswd.password)
        return setError({ password: 'Password cannot be empty' });
      const obj = {
        phone: number,
        code: psswd.password,
        email: credentials.email,
        fullName: credentials.name,
        mode: 'password',
        // posId: posIdScanner ? posIdScanner : "",
      };
      const res = await dispatch(verifyUser(obj))
      if (!state?.hasOwnProperty('item') && res?.payload?.data) {
        setOtpSuccess(true);
        if (
          res?.payload?.data?.roles.includes('user') &&
          res?.payload?.data?.posId
        ) {
          const posRes = await axiosGETCall12('/pospInfo/getPosInfo', {
            id: res?.payload?.data?.posId,
          });
          if (
            posRes.status === 'Success' &&
            ['pending', 'approved'].includes(posRes.data.examStatus)
          ) {
            navigate('/pos', { state: { examStatus: posRes.data.examStatus } });
            return;
          }
          if (
            posRes.status === 'Success' &&
            posRes.data.kycStatus == 'Pending'
          ) {
            navigate(`/partner?id=${posRes.data._id}`);
            return;
          }
        }
        setTimeout(() => {
          if (
            res?.payload?.data?.roles.includes('admin') ||
            res?.payload?.data?.roles.includes('pos') ||
            res?.payload?.data?.roles.includes('employee')
          ) {
            navigate('/dashboard/agent');
            // reload page
            window.location.reload();
          }
          sourcePage === 'work-with-us' ? navigate('/partner') : navigate('/');
          // reload page
          window.location.reload();
        }, 3000);
      } else {
        setError({ password: `${res?.payload?.message}` });
        setTimeout(() => {
          removeError('password');
          setError({ password: '' });
          setPsswd((prev) => ({ ...prev, password: '' }));
          if (res?.payload?.code === 401) return setForm('password-prompt');
        }, 3000);
      }
    }
  };

  const checkBoxStateUpdate = (value) => {
    setCheckBoxState(value);
  };
  const removeError = (key) => {
    switch (key) {
      case 'password':
        return setError({ password: '' });
      case 'cPassword':
        return setError({ cPassword: '' });
      case 'number':
        return setError({ number: '' });
      default:
        break;
    }
  };
  return (
    <>
      <MainContainer>
        <LeftDiv>
          <LeftSubDiv>
            {otpSuccess ? (
              <SubmittedContainer>
                <SubmittedIcon src={SubmittedGif} />
                <p>You have Successfully Logged In</p>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p> */}
              </SubmittedContainer>
            ) : (
              <>
                <HDivApart className="signup-head">
                  <FormHeading>
                    {sourcePage === 'header'
                      ? 'Sign In / Sign Up'
                      : 'POS Sign In / POS Sign Up'}
                  </FormHeading>
                  {form === 'phone' ? (
                    <p className="bold">
                      Already have an account?{' '}
                      <span
                        onClick={() => {
                          setForm('password-prompt');
                        }}
                      >
                        Login
                      </span>
                    </p>
                  ) : form === 'password-prompt' ? (
                    <p className="bold">
                      Don't have an account?{' '}
                      <span onClick={() => setForm('phone')}>Create One</span>
                    </p>
                  ) : null}
                </HDivApart>
                {form === 'otp' ? (
                  <Description className="welcome">
                    Enter the valid OTP shared to{' '}
                    <span className="colored">
                      XXXXXXX{number.substring(number.length - 3)}
                    </span>
                  </Description>
                ) : form === 'password-login' ? (
                  <Description className="welcome">
                    Enter the valid Password linked to{' '}
                    <span className="colored">
                      XXXXXXX{number.substring(number.length - 3)}
                    </span>
                  </Description>
                ) : (
                  <Description>
                    {sourcePage === 'header'
                      ? 'Welcome to Simpli Insure. Please enter your credentials to gain the best rates of insurances in market.'
                      : 'Welcome to Simpli Insure. Quickly search the best insurance product for your customers and sell online.'}
                  </Description>
                )}

                {form === 'phone' ? (
                  <>
                    <InputContainer>
                      <FloatingTitle>Mobile Number</FloatingTitle>
                      <HDiv className="m-0">
                        <h4>+91-</h4>
                        <input
                          maxLength={10}
                          placeholder="Enter Mobile Number"
                          type="tel"
                          onChange={(e) => {
                            const sanitizedValue = only10DigitAndNumber(e);
                            setTyping(true);
                            setNumber(sanitizedValue);
                          }}
                          value={number}
                        />
                        {error.hasOwnProperty('number') ? (
                          <ErrorPopup error={error.number} />
                        ) : null}
                      </HDiv>
                    </InputContainer>

                    <InputContainer>
                      <FloatingTitle>Full Name</FloatingTitle>
                      <HDiv className="m-0">
                        <input
                          placeholder="Enter Full Name"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Allow only letters and spaces
                            const sanitizedValue = inputValue.replace(
                              /[^A-Za-z\s]/g,
                              ''
                            );
                            setTyping(true);
                            setCredentials((prev) => ({
                              ...prev,
                              name: sanitizedValue,
                            }));
                          }}
                          value={credentials.name}
                        />
                        {error.hasOwnProperty('name') ? (
                          <ErrorPopup error={error.name} />
                        ) : null}
                      </HDiv>
                    </InputContainer>

                    <InputContainer className="email">
                      <FloatingTitle>Email ID</FloatingTitle>
                      <>
                        <input
                          placeholder="Enter Email"
                          type={'email'}
                          value={credentials.email}
                          onChange={(e) => {
                            setTyping(true);
                            setCredentials((prev) => ({
                              ...prev,
                              num: '',
                              email: e.target.value,
                            }));
                          }}
                        />
                        {error.hasOwnProperty('email') ? (
                          <ErrorPopup error={error.email} />
                        ) : null}
                      </>
                    </InputContainer>
                  </>
                ) : form === 'password-prompt' ? (
                  <>
                    <InputContainer>
                      <FloatingTitle>Mobile Number</FloatingTitle>
                      <HDiv className="m-0">
                        <h4>+91-</h4>
                        <input
                          maxLength={10}
                          placeholder="Enter Number"
                          onChange={(e) => {
                            const sanitizedValue = only10DigitAndNumber(e);
                            setTyping(true);
                            setNumber(sanitizedValue);
                          }}
                          value={number}
                        />
                        {error.hasOwnProperty('number') ? (
                          <ErrorPopup error={error.number} />
                        ) : null}
                      </HDiv>
                    </InputContainer>
                    <InputContainer className="pass">
                      <FloatingTitle>Password</FloatingTitle>
                      <div className="horizontal">
                        <div className="horizontal apart">
                          <input
                            type={'radio'}
                            id="pass-f"
                            onChange={() => {
                              // setForm('password')
                              checkBoxStateUpdate('password');
                            }}
                            checked={CheckBoxState == 'password' && true}
                          />
                          <label for="pass-f">Password</label>
                        </div>
                        <div className="horizontal apart">
                          <input
                            type={'radio'}
                            id="otp-f"
                            onChange={() => {
                              // setForm('otp')
                              checkBoxStateUpdate('otp');
                            }}
                            checked={CheckBoxState == 'otp' && true}
                          />
                          <label for="otp-f">OTP</label>
                        </div>
                      </div>
                    </InputContainer>
                  </>
                ) : form === 'otp' ? (
                  <InputContainer>
                    <FloatingTitle>Enter OTP</FloatingTitle>
                    <HDivApart className="m-0-0">
                      <input
                        placeholder="XX-XX"
                        onChange={(e) =>
                          setOtp(e.target.value.replace(/\D/, ''))
                        }
                        // onChange={e => setOtp(e.target.value)}
                        value={otp}
                        maxLength={4}
                      />
                      {error.hasOwnProperty('otp') ? (
                        <ErrorPopup error={error.otp} />
                      ) : otp.length === 4 ? (
                        <SuccessIcon src={SuccessIconImg} />
                      ) : null}
                    </HDivApart>
                  </InputContainer>
                ) : form === 'password' ? (
                  <>
                    <InputContainer onClick={() => removeError('password')}>
                      <FloatingTitle>New Password</FloatingTitle>
                      <HDiv className="m-0">
                        <input
                          value={psswd.password}
                          placeholder="Enter Password"
                          type={showPassword ? 'text' : 'password'}
                          onChange={(e) => {
                            setTyping(true);
                            setPsswd((prev) => ({
                              ...prev,
                              password: e.target.value,
                            }));
                          }}
                        />
                        <button onClick={handlePasswordToggle}>
                          {showPassword ? (
                            <IoEyeOffSharp color="F6931E" size="20px" />
                          ) : (
                            <IoEyeSharp color="F6931E" size="20px" />
                          )}
                        </button>
                        {error.password ? (
                          <ErrorPopup error={error.password} />
                        ) : null}
                      </HDiv>
                    </InputContainer>
                    <InputContainer
                      onClick={() => removeError('cPassword')}
                      style={{
                        border:
                          psswd.cPassword === ''
                            ? '1px solid #B7B7B7'
                            : psswd.password === psswd.cPassword
                            ? '1px solid green'
                            : '1px solid red',
                      }}
                    >
                      <FloatingTitle>Confirm Password</FloatingTitle>
                      <HDiv className="m-0">
                        <input
                          value={psswd.cPassword}
                          type="password"
                          placeholder="Confirm Password"
                          onChange={(e) => {
                            setTyping(true);
                            if (!psswd.password)
                              return setError({
                                cPassword: 'Select Password First',
                              });
                            setPsswd((prev) => ({
                              ...prev,
                              cPassword: e.target.value,
                            }));
                          }}
                        />
                        {error.cPassword ? (
                          <ErrorPopup error={error.cPassword} />
                        ) : null}
                      </HDiv>
                    </InputContainer>
                  </>
                ) : form === 'password-login' ? (
                  <>
                    <InputContainer
                      onClick={() => removeError('password')}
                      style={{
                        border: error.password
                          ? '1px solid red'
                          : '1px solid #B7B7B7',
                      }}
                    >
                      <FloatingTitle>Enter Password</FloatingTitle>
                      <HDiv className="m-0">
                        <input
                          value={psswd.password}
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Enter Password"
                          onChange={(e) => {
                            setTyping(true);
                            setPsswd((prev) => ({
                              ...prev,
                              password: e.target.value,
                            }));
                          }}
                        />
                        <button
                          onClick={handlePasswordToggle}
                          className="password_view_hide"
                        >
                          {showPassword ? (
                            <IoEyeOffSharp color="F6931E" size="20px" />
                          ) : (
                            <IoEyeSharp color="F6931E" size="20px" />
                          )}
                        </button>
                        {error.password ? (
                          <ErrorPopup error={error.password} />
                        ) : null}
                      </HDiv>
                    </InputContainer>
                  </>
                ) : null}

                {form === 'otp' ? (
                  <HDivApart>
                    <LoginPassword
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setCheckBoxState('password');
                        setForm('password-login');
                      }}
                    >
                      Login using Password
                    </LoginPassword>
                    {error ? (
                      <Resend
                        onClick={() => {
                          ResendOTP();
                          setError(false);
                          setTimer(30);
                        }}
                        style={{ cursor: 'pointer' }}
                        className="err-resend"
                      >
                        Resend code
                      </Resend>
                    ) : timer === 0 ? (
                      <Resend
                        onClick={() => {
                          ResendOTP();
                          setError(false);
                          setTimer(30);
                        }}
                        style={{ cursor: 'pointer' }}
                        className="err-resend"
                      >
                        Resend code
                      </Resend>
                    ) : (
                      <Resend>
                        Resend code : <span>{timer} Sec</span>
                      </Resend>
                    )}
                  </HDivApart>
                ) : form === 'password-login' ? (
                  <HDivApart style={{ cursor: 'pointer' }}>
                    <LoginPassword
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setForm('otp');
                      }}
                    >
                      Login using OTP
                    </LoginPassword>

                    <Link
                      to="/reset-password"
                      style={{ cursor: 'pointer' }}
                      className="err-resend"
                    >
                      Forgot Password
                    </Link>
                  </HDivApart>
                ) : form === 'phone' ? (
                  <Description className="terms">
                    By continuing, you agree to the{' '}
                    <span className="tnc" style={{ cursor: 'pointer' }}>
                      Terms of Use
                    </span>{' '}
                    and{' '}
                    <span style={{ cursor: 'pointer' }} className="privacy">
                      Privacy Policy
                    </span>{' '}
                    of <span className="simpli">Simpli</span>
                    <span className="insure">Insure</span>
                  </Description>
                ) : null}

                {form === 'phone' ? (
                  <>
                    <ContinueBtn
                      onClick={() => {
                        submitFun();
                      }}
                    >
                      {name.auth.isFetching ? (
                        <Oval
                          height={25}
                          width={70}
                          color="#fff"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#fff"
                          strokeWidth={2}
                          strokeWidthSecondary={2}
                        />
                      ) : (
                        'Continue'
                      )}
                    </ContinueBtn>
                  </>
                ) : form === 'otp' ? (
                  <ContinueBtn
                    onClick={() => {
                      setOtp(otp);
                      submitSignupOTP();
                    }}
                  >
                    {name.auth.isFetching ? (
                      <Oval
                        height={25}
                        width={70}
                        color="#fff"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#fff"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : (
                      'Continue'
                    )}
                  </ContinueBtn>
                ) : form === 'password' ? (
                  <ContinueBtn
                    onClick={() => {
                      submitFun();
                    }}
                  >
                    Continue
                  </ContinueBtn>
                ) : form === 'password-login' ? (
                  <ContinueBtn
                    onClick={() => {
                      submitOTP();
                    }}
                  >
                    Continue
                  </ContinueBtn>
                ) : form === 'password-prompt' ? (
                  <ContinueBtn
                    onClick={() => {
                      if (!validation(number, 'mob').status)
                        return setError({ number: 'Enter Valid Number' });
                      submitFun();
                    }}
                  >
                    {name.auth.isFetching ? (
                      <Oval
                        height={25}
                        width={70}
                        color="#fff"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#fff"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : (
                      'Continue'
                    )}
                  </ContinueBtn>
                ) : null}
                {isScanner ? (
                  <div
                    style={{
                      padding: '10px',
                      borderRadius: '12px',
                      background:
                        'linear-gradient(135deg, #7b1fa2, #512da8, #673ab7)',
                      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                      textAlign: 'center',
                      maxWidth: '400px',
                      margin: '30px auto',
                      color: '#fff',
                      fontFamily: "'Roboto', sans-serif",
                      position: 'relative',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '20px',
                        fontWeight: '600',
                        margin: '0',
                        padding: '15px',
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        borderRadius: '8px',
                        boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
                        display: 'inline-block',
                      }}
                    >
                      Your POS has been successfully assigned under the name:{' '}
                      {responseFinal
                        ? responseFinal.data.data.data[0].fullName
                        : 'Loading...'}
                    </p>
                  </div>
                ) : null}
              </>
            )}
          </LeftSubDiv>
        </LeftDiv>
        <RightDiv>
          <Carousal />
        </RightDiv>
      </MainContainer>
      <Footer />
    </>
  );
}