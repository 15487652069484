import React, {useState} from 'react';
import './newslatter.css';
import CareerImage from '../../assets/banner/5.png';
import { FaXTwitter, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { IoLogoWhatsapp, IoMdMail } from "react-icons/io";
import FooterNew from '../../components/Footer/FooterNew';
import Hero from '../../components/Hero_Banner/hero';
import Quick from '../../components/new_quick_links/quick';
import { FaNewspaper } from "react-icons/fa";
import faqBanner from '../../assets/newslatter.png';
import { newsLatter } from "../../Redux/AuthRedux";
import { useDispatch } from 'react-redux';

const NewsLatter = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState(""); 
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    if (name === "" || email === "") {
      alert("Please fill all the fields");
      return;
    }

    e.preventDefault();
    dispatch(newsLatter({name, email})).then (res => {
      if (res) {
        alert(res?.payload?.data);
        }
        setName("");
        setEmail("");
    });
  };


  const data = {
    values: [
      {
        title: "Industry Insights:",
        description: "Stay informed about changes, updates, and developments in the insurance landscape."
      },
      {
        title: "Helpful Tips:",
        description: "Receive expert advice and tips on optimizing your insurance coverage and understanding policy options."
      },
      {
        title: "Exclusive Offers:",
        description: "Work alongside talented individuals who value teamwork, diversity, and continuous learning."
      },
      {
        title: "Company Updates:",
        description: "Be the first to know about Simpli Insure’s latest initiatives, events, and community involvement."
      },
    ]
  };

    const Card = ({ title, description }) => {
    return (
      <div className="card">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    );
  };

  return (
    <div>
      <Hero
        icon={<FaNewspaper size={100} />}
        heading={'Empowering SUSTAINABLE AND Secure Choices'}
        image={faqBanner}
        subHeading={'Stay Informed. Empower Your Insurance Decisions'}
        btn={true}
      />
      <Quick heading={'Newsletter'} />
      <div className="careerContainer">
        <h1 className="career_heading ">NEWSLETTER</h1>
        <div className="joinTeam">
          <h2>Stay Informed with Simpliinsure.com’s Newsletter</h2>
          <p>
            We are dedicated to keeping you updated on the latest news,
            insights, and trends in the insurance industry. Our newsletter is
            your gateway to valuable information, tips, and exclusive offers
            designed to simplify your insurance experience.
          </p>
        </div>

        <div className="whyJoin">
          <h2 className="career_heading_small">WHAT YOU CAN EXPECT:</h2>
          <div className="cards-container">
            {data.values.map((item, index) => (
              <Card
                key={index}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
        </div>

        <div className="jobOpenings">
          <p>
            Join our community and stay connected with Simpli Insure. Enter your
            email address below to subscribe to our newsletter and start
            receiving valuable insights straight to your inbox.
          </p>
        </div>

        <div className="submitResumeForm">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={name}
              placeholder="Enter your name"
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              name="email"
              value={email}
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit">Submit</button>
          </form>
        </div>

        <div className="diversity">
          <h2 className="career_heading_small">MANAGE YOUR SUBSCRIPTION</h2>
          <p>
            Already subscribed but want to update your preferences or
            unsubscribe? Manage your subscription settings through our
            subscription management portal.{' '}
            <span>
              <a style={{ color: '#f6931e' }} href="#">
                Subscription Management
              </a>
            </span>
          </p>
        </div>

        <div className="socialMedia">
          <h2 className="career_heading_small">CONNECT WITH US</h2>
          <div className="socialMediaIcons">
            <a
              href="https://www.facebook.com/SimpliInsureOfficial"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook size="1.3rem" color="#000" />
            </a>
            <a
              href="http://www.instagram.com/simpliinsureofficial/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram size="1.3rem" className="iconSpace" color="#000" />
            </a>
            <a
              href="https://wa.me/+919606045764?text=Hi%20SimpliInsure,%20I%20have%20a%20query%20regarding%20my%20insurance%20policy."
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoWhatsapp
                size="1.3rem"
                className="iconSpace"
                color="#000"
              />
            </a>
            <a
              href="http://www.twitter.com/SimpliInsure"
              target="_blank"
              rel="noreferrer"
            >
              <FaXTwitter size="1.3rem" className="iconSpace" color="#000" />
            </a>
            <a
              href="http://www.linkedin.com/company/simpliinsure/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin size="1.3rem" className="iconSpace" color="#000" />
            </a>
            <a
              href="http://www.youtube.com/@SimpliInsureOfficial"
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube size="1.3rem" className="iconSpace" color="#000" />
            </a>
          </div>
        </div>
      </div>
      <FooterNew />
    </div>
  );
}

export default NewsLatter;
