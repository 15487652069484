  import React, { useState, useEffect } from 'react';
  import './PolicyParticularDetails.css';

  const PolicyParticularDetails = ({
    formData,
    handleAddData,
    addedData,
    handleDelete,
  }) => {
    const [localFormData, setLocalFormData] = useState({
      sNo: 1,
      coverage: '',
      extensions: formData?.policyType ? formData?.policyType : '',
      sumInsured: 0,
      premRate: 0,
      netOdPrem: 0,
      locationRemarks: '',
      gstTax: 0,
      multiInstPolicy: 'No',
      tpSumInsured: 0,
      tpPremium: 0,
      rsaPremium: 0,
      totalSumInsured: 0,
      brokPremium: 0,
      ownerDriverPremium: 0,
      netPremium: 0,
      gstSTax: 0,
      stampDuty: 0,
      grossPremium: 0,
      oddDtd: 0,
    });

        const [finalPolicyData, setFinalPolicyData] = useState({
          sumInsured: 0,
          gstTax: 0,
          tpSumInsured: 0,
          tpPremium: 0,
          rsaPremium: 0,
          totalSumInsured: 0,
          brokPremium: 0,
          ownerDriverPremium: 0,
          netPremium: 0,
          gstSTax: 0,
          stampDuty: 0,
          grossPremium: 0,
          oddDtd: 0,
        });


    const handleChange = (e) => {
      const { name, value } = e.target;
      setLocalFormData({
        ...localFormData,
        [name]: value,
      });
    };

    const handleAddLocalData = () => {
      let updatedFormData = { ...localFormData };

      if (localFormData.coverage === 'privateCar') {
        updatedFormData.brokPremium = localFormData.netOdPrem;
        finalPolicyData.brokPremium = localFormData.netOdPrem;
      } else if (localFormData.coverage === 'Third Party') {
        updatedFormData.tpPremium = localFormData.netOdPrem;
        finalPolicyData.tpPremium = localFormData.netOdPrem;
      } else if (localFormData.coverage === 'ownerDriver') {
        updatedFormData.ownerDriverPremium = localFormData.netOdPrem;
        finalPolicyData.ownerDriverPremium = localFormData.netOdPrem;
      }
      //  net premium calculation
      updatedFormData.netPremium =
        Number.parseFloat(updatedFormData.brokPremium || 0) +
        Number.parseFloat(updatedFormData.tpPremium || 0) +
        Number.parseFloat(updatedFormData.rsaPremium || 0) +
        Number.parseFloat(updatedFormData.ownerDriverPremium || 0) +
        Number.parseFloat(updatedFormData.stampDuty || 0);

      finalPolicyData.netPremium = updatedFormData.netPremium;

      // calculate gst
      if (updatedFormData.netPremium && updatedFormData.gstSTax) {
        updatedFormData.gstTax =
          (updatedFormData.netPremium *
            Number.parseFloat(updatedFormData.gstSTax)) /
          100;

        finalPolicyData.gstTax = updatedFormData.gstTax;
        finalPolicyData.sumInsured = updatedFormData.sumInsured;
        finalPolicyData.tpSumInsured = updatedFormData.tpSumInsured;
        finalPolicyData.totalSumInsured = updatedFormData.totalSumInsured;
        finalPolicyData.gstSTax = updatedFormData.gstSTax;

        console.log('GST Calculated:', updatedFormData.gstTax);
      }

      // for testing
            updatedFormData.grossPremium =
              Number.parseFloat(updatedFormData.netPremium || 0) +
              Number.parseFloat(updatedFormData.gstTax || 0);

            finalPolicyData.grossPremium = updatedFormData.grossPremium;


      setLocalFormData((prevState) => ({
        ...prevState,
        ...updatedFormData,
      }));

      setFinalPolicyData((prevState) => ({
        ...prevState,
        ...finalPolicyData,
      }));

      // Call handleAddData with updated form data
      handleAddData(updatedFormData, finalPolicyData);
    };

    
    const handleCalculateGrossPrem = () => {
      let updatedFormData = { ...localFormData };

      // Calculate gross premium
      updatedFormData.grossPremium =
        Number.parseFloat(updatedFormData.netPremium || 0) +
        Number.parseFloat(updatedFormData.gstTax || 0);

      finalPolicyData.grossPremium = updatedFormData.grossPremium;

      console.log('Gross Premium Calculated:', updatedFormData.grossPremium);

      // Update only the localFormData with the calculated gross premium
      setLocalFormData((prevState) => ({
        ...prevState,
        grossPremium: updatedFormData.grossPremium,
      }));

      setFinalPolicyData((prevState) => ({
        ...prevState,
        grossPremium: updatedFormData.grossPremium,
      }));

    };


    return (
      <div className="policy-particular-details">
        <h2>Policy Particular and Premium Details</h2>

        {/* Part 1 of the form */}
        <div className="policy-particular-grid">
          <div className="form-group">
            <label htmlFor="sNo" className="policyParticularLabel">
              S.No
            </label>
            <input
              type="text"
              id="sNo"
              name="sNo"
              className="policyParticularInput"
              value={localFormData.sNo}
              onChange={handleChange}
              disabled
            />
          </div>

          <div className="form-group">
            <label htmlFor="coverage" className="policyParticularLabel">
              Coverage/TP
            </label>
            <select
              id="coverage"
              name="coverage"
              className="policyParticularSelect"
              value={localFormData.coverage}
              onChange={handleChange}
            >
              <option value="">--Select Extension/Coverage</option>
              <option default value="privateCar">
                privateCar
              </option>
              <option value="addon cover">Addon Cover</option>
              <option value="Third Party">Third Party (TP)</option>
              <option value="ownerDriver">ownerDriver(LPD)</option>
              <option value="roadside assistance">Roadside Assistance</option>
            </select>
          </div>

          <div className="form-group required">
            <label htmlFor="extensions" className="policyParticularLabel">
              Extensions<span className="required-strick">*</span>
            </label>
            <select
              id="extensions"
              name="extensions"
              className="policyParticularSelect"
              value={localFormData.extensions}
              onChange={handleChange}
              required
            >
              <option value={formData.policyType ? formData.policyType : ''}>
                {formData.policyType
                  ? formData.policyType
                  : '--Select Extension--'}
              </option>
            </select>
          </div>

          {localFormData.coverage  !== 'Third Party' && (
            <div className="form-group required">
              <label htmlFor="sumInsured" className="policyParticularLabel">
                Sum Insured{' '}
                {formData.policyType === 'STANDALONE POLICY' ? (
                  ''
                ) : (
                  <span className="required-strick">*</span>
                )}
              </label>
              <input
                type="number"
                id="sumInsured"
                name="sumInsured"
                className="policyParticularInput"
                value={localFormData.sumInsured}
                onChange={handleChange}
                required={
                  formData.policyType === 'STANDALONE POLICY' ? false : true
                }
              />
            </div>
          )}

          <div className="form-group">
            <label htmlFor="premRate" className="policyParticularLabel">
              Prem. Rate
            </label>
            <input
              type="number"
              id="premRate"
              name="premRate"
              className="policyParticularInput"
              value={localFormData.premRate}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="netOdPrem" className="policyParticularLabel">
              Net/OD Prem.
            </label>
            <input
              type="number"
              id="netOdPrem"
              name="netOdPrem"
              className="policyParticularInput"
              value={localFormData.netOdPrem}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="locationRemarks" className="policyParticularLabel">
              Location and Remarks
            </label>
            <input
              type="text"
              id="locationRemarks"
              name="locationRemarks"
              className="policyParticularInput"
              value={localFormData.locationRemarks}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="gstSTax" className="policyParticularLabel">
              GST/Tax%
            </label>
            <select
              id="gstSTax"
              name="gstSTax"
              className="policyParticularInput"
              value={localFormData.gstSTax}
              onChange={handleChange}
            >
              <option value="0.00">0.00</option>
              <option value="10.30">10.30</option>
              <option value="12.00">12.00</option>
              <option value="12.24">12.24</option>
              <option value="12.36">12.36</option>
              <option value="14.00">14.00</option>
              <option value="14.50">14.50</option>
              <option value="15.00">15.00</option>
              <option value="18.00">18.00</option>
            </select>
          </div>

          <div className="form-group">
            <button
              type="button"
              className="add-btn"
              onClick={handleAddLocalData}
            >
              Add
            </button>
          </div>
        </div>

        <h3>Added Premium Details</h3>
        <table className="policy-particular-table">
          <thead>
            <tr>
              <th>Action</th>
              <th>S.No</th>
              <th>Coverage</th>
              <th>Extensions</th>
              <th>Sum Insured</th>
              <th>Prem. Rate</th>
              <th>Net/OD Prem</th>
              <th>Location Remarks</th>
              <th>GST/Tax%</th>
            </tr>
          </thead>
          <tbody>
            {addedData.map((data, index) => (
              <tr key={index}>
                <td>
                  <button type="button" onClick={() => handleDelete(index)}>
                    Delete
                  </button>
                </td>
                <td>{index + 1}</td>
                <td>{data.coverage}</td>
                <td>{data.extensions}</td>
                <td>{data.sumInsured}</td>
                <td>{data.premRate}</td>
                <td>{data.netOdPrem}</td>
                <td>{data.locationRemarks}</td>
                <td>{data.gstSTax}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <hr style={{ marginTop: '20px', marginBottom: '20px' }} />

        {/* Part 2 of the form */}
        <div className="policy-particular-grid">
          <div className="form-group">
            <label htmlFor="multiInstPolicy" className="policyParticularLabel">
              Multi-Inst. Policy
            </label>
            <select
              id="multiInstPolicy"
              name="multiInstPolicy"
              className="policyParticularSelect"
              value={localFormData.multiInstPolicy}
              onChange={handleChange}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="tpSumInsured" className="policyParticularLabel">
              TP Sum Insured
            </label>
            <input
              type="number"
              id="tpSumInsured"
              name="tpSumInsured"
              className="policyParticularInput"
              value={localFormData.tpSumInsured}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="tpPremium" className="policyParticularLabel">
              TP Premium
            </label>
            <input
              type="number"
              id="tpPremium"
              name="tpPremium"
              className="policyParticularInput"
              value={localFormData.tpPremium}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="rsaPremium" className="policyParticularLabel">
              RSA Premium
            </label>
            <input
              type="number"
              id="rsaPremium"
              name="rsaPremium"
              className="policyParticularInput"
              value={localFormData.rsaPremium}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="totalSumInsured" className="policyParticularLabel">
              Total Sum Insured
            </label>
            <input
              type="number"
              id="totalSumInsured"
              name="totalSumInsured"
              className="policyParticularInput"
              value={addedData[0]?.sumInsured ? addedData[0]?.sumInsured : 0}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="ownerDriverPremium"
              className="policyParticularLabel"
            >
              Owner Driver Premium
            </label>
            <input
              type="number"
              id="ownerDriverPremium"
              name="ownerDriverPremium"
              className="policyParticularInput"
              value={localFormData.ownerDriverPremium}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="brokPremium" className="policyParticularLabel">
              Brok Premium
            </label>
            <input
              type="number"
              id="brokPremium"
              name="brokPremium"
              className="policyParticularInput"
              value={localFormData.brokPremium}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="netPremium" className="policyParticularLabel">
              Net Premium
            </label>
            <input
              type="number"
              id="netPremium"
              name="netPremium"
              className="policyParticularInput"
              value={localFormData.netPremium}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="gstSTax" className="policyParticularLabel">
              GST/S. Tax(%)
            </label>
            <input
              type="number"
              id="gstSTax"
              name="gstSTax"
              className="policyParticularInput"
              value={localFormData.gstSTax}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="gstTax" className="policyParticularLabel">
              GST/S. Tax
            </label>
            <input
              type="number"
              id="gstTax"
              name="gstTax"
              className="policyParticularInput"
              value={localFormData.gstTax}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="stampDuty" className="policyParticularLabel">
              Stamp Duty
            </label>
            <input
              type="number"
              id="stampDuty"
              name="stampDuty"
              className="policyParticularInput"
              value={localFormData.stampDuty}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="grossPremium" className="policyParticularLabel">
              Gross Premium
            </label>
            <input
              type="number"
              id="grossPremium"
              name="grossPremium"
              className="policyParticularInput"
              value={localFormData.grossPremium}
              disabled
            />
          </div>

          <div className="form-group">
            <label htmlFor="oddDtd" className="policyParticularLabel">
              ODD/DTD(%)
            </label>
            <input
              type="number"
              id="oddDtd"
              name="oddDtd"
              className="policyParticularInput"
              value={localFormData.oddDtd}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* <button
          type="button"
          onClick={handleCalculateGrossPrem}
          className="calc-gross-prem-btn"
        >
          Calc Gross Prem
        </button> */}
      </div>
    );
  };

  export default PolicyParticularDetails;

