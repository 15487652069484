import React, { useEffect } from 'react';
import { Modal, Input, Button } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import EditUserSvg from '../assets/dashboard/edit-img.svg';

// pos request model data
const RequestModelData = ({
  posInfo,
  isModalVisible,
  getFirstLetter,
  handleCancel,
  rejectReason,
  handleInputChange,
  handleOk,
  modalCloses,
  downloadImage,
  showModal,
  approvePos,
}) => {
  const navigate = useNavigate();
  var userDetails = localStorage.getItem('userDetails');

  const handleIrdaSection = () => {
    localStorage.setItem('irdaReviewNumber', posInfo._id);
    navigate('/review');
  };

  return (
    <div>
      {
        <Modal
          visible={isModalVisible}
          // onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="modal-overlay">
            <div className="modal">
              <h2>Enter Rejection Reason</h2>
              <textarea
                id="rejectReason"
                name="rejectReason"
                value={rejectReason}
                onChange={handleInputChange}
                placeholder="Enter rejection reason"
              />
              <div className="modal-buttons">
                <button onClick={handleCancel}>Cancel</button>
                <button onClick={handleOk}>Submit</button>
              </div>
            </div>
          </div>
        </Modal>
      }
      <div id="myModal" className="modalPos">
        <div className="modal-content">
          <div className="close" onClick={modalCloses}>
            &times;
          </div>

          <div className="inside_box">
            {console.log('POS IDssss:', posInfo?._id)}
            {userDetails &&
            JSON.parse(userDetails).roles[0] === 'admin' &&
            !['completed', 'approved'].includes(posInfo?.examStatus) &&
            posInfo?._id ? (
              <Link to={`/partner?id=${posInfo?._id}`}>
                <button
                  onClick={() => {
                    localStorage.setItem('sourcePage', 'adminDash');
                  }}
                  style={{
                    backgroundColor: '#F6931E',
                    marginLeft: '10px',
                    marginTop: '10px',
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  Complete Form
                </button>
              </Link>
            ) : null}
            <div className="profile_div">
              <img
                alt="profile"
                src={
                  posInfo?.documents?.profile ||
                  `https://placehold.co/600x400?text=${getFirstLetter(
                    posInfo?.fullName
                  )}`
                }
                className="profile_photo"
              />
            </div>
            <div className="content_popup">
              <div className="popup_head">
                <h3 className="heading">POS Details :</h3>
              </div>
              <div className="rm_basic">
                <div className="rm_right">
                  <div>
                    <h3>
                      Member Since : <span>20/01/2020</span>
                    </h3>
                    <h3>
                      POS ID :<span>{posInfo?._id}</span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      POS Name : <span>{posInfo?.fullName}</span>
                    </h3>
                    <h3>
                      POS Email :<span>{posInfo?.email}</span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      POS Mobile No. : <span>+91 - {posInfo?.phone}</span>
                    </h3>
                    <h3>
                      Sr. RM/No. :
                      <span>
                        {posInfo?.informationRM?.name}/+91 -{' '}
                        {posInfo?.informationRM?.phone}
                      </span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      House No. : <span>{posInfo?.houseNo}</span>
                    </h3>
                    <h3>
                      Street :<span>{posInfo?.street}</span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      Town : <span>{posInfo?.town}</span>
                    </h3>
                    <h3>
                      District :
                      <span>
                        {posInfo?.district} ({posInfo?.area})
                      </span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      State : <span>{posInfo?.state}</span>
                    </h3>
                    <h3>
                      Pincode :<span>{posInfo?.pincode}</span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      Area : <span>{posInfo?.area}</span>
                    </h3>
                  </div>
                </div>
              </div>
              <hr className="popUp_line" />

              <h3 className="heading">Other Details :</h3>
              <div className="rm_basic">
                <div className="rm_right">
                  <div>
                    <h3>
                      Gender :<span>{posInfo?.gender}</span>
                    </h3>
                    <h3>
                      D.O.B :
                      <span>{moment(posInfo?.dob).format('DD/MM/YYYY')}</span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      Pan Card :<span>{posInfo?.panNo}</span>
                    </h3>
                    <h3>
                      Basic Quilification :<span>{posInfo?.qualification}</span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      IRDA Number:{' '}
                      {posInfo?.irdaNumber ? (
                        <span>{posInfo.irdaNumber}</span>
                      ) : (
                        <span className="edit_div">
                          <span
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            onClick={handleIrdaSection}
                          >
                            Upload IRDA Number & Document
                          </span>{' '}
                          <img
                            className="edit_docs"
                            style={{ cursor: 'pointer' }}
                            src={EditUserSvg}
                            alt="irda"
                            onClick={handleIrdaSection}
                          />
                          <input type="text" style={{ display: 'none' }} />
                        </span>
                      )}
                    </h3>

                    <h3>
                      IRDA Document :
                      <span onClick={downloadImage(posInfo)}>Download</span>
                    </h3>
                  </div>
                </div>
              </div>
              <hr className="popUp_line" />

              <h3 className="heading">KYC & Status Details :</h3>
              <div className="rm_basic">
                <div className="rm_right">
                  <div>
                    <h3>
                      KYC Status :
                      <span>{posInfo?.documents ? 'Pending' : 'Approved'}</span>
                    </h3>
                    <h3>
                      Status :-<span></span>
                    </h3>
                  </div>
                </div>
              </div>
              <hr className="popUp_line" />

              <h3 className="heading">Document Details :</h3>
              <div className="rm_basic">
                <div className="rm_details">
                  <div className="details_front">
                    {/* Aadhar Card Front */}
                    <div>
                      <h3>Aadhar card Front :</h3>
                      {posInfo?.documents?.aadharFront?.endsWith('.pdf') ? (
                        <iframe
                          src={`https://docs.google.com/gview?url=${encodeURIComponent(
                            posInfo.documents.aadharFront
                          )}&embedded=true`}
                          width="500px"
                          height="300px"
                          title="Aadhar Front PDF Preview"
                          style={{ border: '1px solid #ccc' }}
                        ></iframe>
                      ) : (
                        <img
                          src={
                            posInfo?.documents?.aadharFront ||
                            `https://placehold.co/600x400?text=AADHAR FRONT`
                          }
                          alt="Aadhar Front"
                        />
                      )}
                    </div>

                    {/* Pan Card Front */}
                    <div>
                      <h3>Pan card Front :</h3>
                      {posInfo?.documents?.pancard?.endsWith('.pdf') ? (
                        <iframe
                          src={`https://docs.google.com/gview?url=${encodeURIComponent(
                            posInfo.documents.pancard
                          )}&embedded=true`}
                          width="500px"
                          height="300px"
                          title="Pan Card PDF Preview"
                          style={{ border: '1px solid #ccc' }}
                        ></iframe>
                      ) : (
                        <img
                          src={
                            posInfo?.documents?.pancard ||
                            `https://placehold.co/600x400?text=PAN FRONT`
                          }
                          alt="Pan Card Front"
                        />
                      )}
                    </div>

                    {/* Education Qualification Proof */}
                    <div>
                      <h3>Education Qualification Proof :</h3>
                      {posInfo?.documents?.education?.endsWith('.pdf') ? (
                        <iframe
                          src={`https://docs.google.com/gview?url=${encodeURIComponent(
                            posInfo.documents.education
                          )}&embedded=true`}
                          width="500px"
                          height="300px"
                          title="Education Proof PDF Preview"
                          style={{ border: '1px solid #ccc' }}
                        ></iframe>
                      ) : (
                        <img
                          src={
                            posInfo?.documents?.education ||
                            `https://placehold.co/600x400?text=EDUCATION`
                          }
                          alt="Education Qualification Proof"
                        />
                      )}
                    </div>
                  </div>

                  <div className="details_back">
                    {/* Aadhar Card Back */}
                    <div>
                      <h3>Aadhar card Back :</h3>
                      {posInfo?.documents?.aadharBack?.endsWith('.pdf') ? (
                        <iframe
                          src={`https://docs.google.com/gview?url=${encodeURIComponent(
                            posInfo.documents.aadharBack
                          )}&embedded=true`}
                          width="500px"
                          height="300px"
                          title="Aadhar Back PDF Preview"
                          style={{ border: '1px solid #ccc' }}
                        ></iframe>
                      ) : (
                        <img
                          src={
                            posInfo?.documents?.aadharBack ||
                            `https://placehold.co/600x400?text=AADHAR BACK`
                          }
                          alt="Aadhar Back"
                        />
                      )}
                    </div>

                    {/* Placeholder for missing section */}
                    <div>
                      <h3> </h3>
                    </div>

                    {/* Signature Proof */}
                    <div>
                      <h3>Signature Proof :</h3>
                      {posInfo?.documents?.signature?.endsWith('.pdf') ? (
                        <iframe
                          src={`https://docs.google.com/gview?url=${encodeURIComponent(
                            posInfo.documents.signature
                          )}&embedded=true`}
                          width="500px"
                          height="300px"
                          title="Signature Proof PDF Preview"
                          style={{ border: '1px solid #ccc' }}
                        ></iframe>
                      ) : (
                        <img
                          src={
                            posInfo?.documents?.signature ||
                            `https://placehold.co/600x400?text=SIGNATURE`
                          }
                          alt="Signature Proof"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accept_reject">
              <button className="reject" onClick={showModal}>
                <p>Reject</p>
              </button>
              <button
                className="approve"
                onClick={approvePos}
                disabled={!posInfo?.irdaImg && !posInfo?.documents}
              >
                <p>Approve</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestModelData;
