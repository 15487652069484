const SelectComponent = ({ options, value, onChange }) => {
    return (
        <select
                value={value}
                onChange={onChange}
                className="search-select"
                style={{
                  padding: '4px 8px',
                  borderRadius: '4px',
                  border: '1px solid #ddd',
                  outline: 'none',
                  backgroundColor: 'white',
                  marginRight: '8px'
                }}
              >
                {options.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.key}
                  </option>
                ))}
              </select>
    );
};
export default SelectComponent;