import axios from "axios";

export async function uploadFile(file) {
    try{
    // event.preventDefault()
    const token = localStorage.getItem('Authorization');

    const url = `${process.env.REACT_APP_API_URL}/uploadFile/file`;
    let formData = new FormData();
    console.log("file name is ", file.name);
    formData.append('files', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      },
    };
    console.log("form data is", formData);

    const urlRe = await axios.post(url, formData, config)
    return urlRe.data
    }
    catch(err){
        console.log(
            "err",err
        )
    }

  }
