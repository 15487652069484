import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header/Header'
import './App.css'
import Home from './pages/Home/Home'
import Term from './pages/life-insurance/forms/Term/Term.jsx'
import { Form2 } from './pages/life-insurance/forms/form-2/Form2'
import { GetQuotes } from './pages/life-insurance/forms/form-4/GetQuotes'
import { CarInsurance } from './pages/car-insurance/CarInsurance'
import { CarForm2 } from './pages/car-insurance/form-2/Form2'
import { CarForm3 } from './pages/car-insurance/form-3/Form3'
import { CarForm4 } from './pages/car-insurance/form-4/Form4'
import { CarForm5 } from './pages/car-insurance/form-5/Form5'
import { CarForm7 } from './pages/car-insurance/form-7/Form7'
import { CarForm8 } from './pages/car-insurance/form-8/Form8'
import { CarForm9 } from './pages/car-insurance/form-9/Form9'
import { CarForm10 } from './pages/car-insurance/form-10/Form10'
import { CarForm11 } from './pages/car-insurance/form-11/Form11'
import { CarForm6 } from './pages/car-insurance/form-6/Form6'
import { SigninSignup } from './pages/signin-signup/signin-signup'

import { BrandModel } from './pages/brand-new-car/brand-model/brand-model'
import { SelectModel } from './pages/brand-new-car/select-model/select-model'
import { CarDetailsForm, CarDetailsForm1, ColorChassis } from './pages/brand-new-car/car-details-form1/car-details-form1'
import { CarDetailsForm2 } from './pages/brand-new-car/car-details-form2/car-details-form2'
import { CarDetailsForm3 } from './pages/brand-new-car/car-details-form3/car-details-form3'
import { CarDetailsForm4 } from './pages/brand-new-car/car-details-form4/car-details-form4'
import { CarDetailsForm5 } from './pages/brand-new-car/car-details-form5/car-details-form5'
import { CarDetailsForm6 } from './pages/brand-new-car/car-details-form6/car-details-form6'
import { CarFormPreview } from './pages/brand-new-car/car-form-preview/car-form-preview'
import { Provider } from "react-redux";
import { store } from "../src/Redux";
import { PolicyDetails, TypeofPolicy } from './pages/policy-details/type-of-policy/type-of-policy'
import { TypeofPolicy2 } from './pages/policy-details/type-of-policy2/type-of-policy2'
import { TypeOfPolicy3 } from './pages/policy-details/type-of-policy3/type-of-policy3'
import { StandaloneTp } from './pages/policy-details/standalone-tp/standalone-tp'
import { PrevInsuranceDetails } from './pages/policy-details/prev-insurance-details/prev-insurance-details'
import { IntroScreen } from './pages/intro-screen/intro-screen'
import  {LifeIntroScreen}  from './pages/life-insurance/life-intro-screen'
import { CarMainPage } from './pages/car-insurance/main-page/main-page'
import { InsurancePopup } from './pages/car-insurance/insurance-popup/insurance-popup'
import { ViewDetailsCar1 } from './pages/car-insurance/view-details1/view-details1'
import { CarPayment1 } from './pages/car-insurance/payments/payment-1/payment1'
import { CarPayment3 } from './pages/car-insurance/payments/payment-3/payment3'
import { CarPayment4 } from './pages/car-insurance/payments/payment-4/payment4'
import {Instructions} from './pages/car-insurance/payments/breakin-instruction/instructions'
import { CarPayment5 } from './pages/car-insurance/payments/payment-5/payment5.js'
import { CarPaymentSuccess } from './pages/car-insurance/payments/payment-success/payment-success'
import { AgentDashboard } from './pages/dashboard/agent-dashboard/agent-dashboard'
import { AboutUs } from './pages/footer-pages/about-us/about-us'
import { PrivacyPolicy } from './pages/footer-pages/privacy-policy/privacy-policy'
import { Terms } from './pages/footer-pages/terms/terms'
import { ContactUs } from './pages/footer-pages/contact-us/contact-us'
import { Refunds } from './pages/footer-pages/refunds/refunds';
import { POS } from './components/POS'
import { Products } from './pages/products/Products'
import { Corporate } from './pages/corporates/Corporate'
import {AdminDashboard} from './pages/admin/admin'
import Address from './components/POS/components/Address'
import Language from './components/POS/components/Language'
import Basic from './components/POS/components/Basic'
import SEGMENT from './components/POS/components/SEGMENT'
import Upload from './components/POS/components/Upload'
import Done from './components/POS/components/Done'
import Review from './components/POS/components/Review'
import Pos from './components/POS/components/Pos'
import {LifeMainScreen} from "./pages/life-insurance/main/life-main-screen";
import DynamicVariants from './pages/car-insurance/dynamic-variants/dynamic-variants'
import { PreviewAfterPayment } from './pages/car-insurance/payments/preview-after-payment/preview'
import { IoLogoWhatsapp, IoMdInformationCircleOutline } from "react-icons/io";

// New Menu 
import { menuItems } from './components/Header/menuItems'
import NotFoundPage from './pages/404.jsx'
import Sitemap from './pages/footer-pages/sitemap/sitemap.jsx';
import PransantPage from './pages/footer-pages/management-team/nimgade-page.jsx';
import ShandePage from './pages/footer-pages/management-team/shande-page.jsx'
import PandePage from './pages/footer-pages/management-team/pande-page.jsx'
import Careers from './pages/careers/career.jsx'
import NewsLatter from './pages/newslatter/newslatter.jsx'
import HealthInsurance from './pages/linkPages/health-insurance/HealthInsurance.js'
import LifeInsurance from './pages/linkPages/life-insurance/LifeInsurance.js'
import FAQSection from './pages/FAQSection/faq.js'
import  BlogList  from './pages/blogSection/blogList.js'
import  BlogDetail  from './pages/blogSection/blogDetail.js'
import blogData from './pages/blogSection/blogData.js'
import {axiosPOSTCall1}  from "../src/utilities/utils/apiPost.js"


// reset password
import MobileNumberPage from './components/reset-password/mobile'
import OTPPage from './components/reset-password/otp'
import PasswordPage from './components/reset-password/password'
import SuccessPage from './components/reset-password/success'

// new user banner
// import newUserBanner from "./assets/newUserBanner.png"
// import { FaTimes } from 'react-icons/fa';
import MainBrandPage from './pages/brand-main-page/main-brand-page';

// for scroll to top ----
import ScrollToTop from './utilities/scrollToTop.jsx';

// Saiba Section
import PolicyForm from './pages/saibaSection/PolicyForm';

import PolicyUpload from './components/saibaSection/policyUpload.js'
import PolicyUploadAdmin from './components/policy-upload-dash-admin/policy-upload-admin';
import CustomerFeadback from './pages/customerFeadback/CustomerFeadback';


const App = () => {
  // const [showBanner, setShowBanner] = useState(false);

  //   useEffect(() => {
  //   const hasVisitedBefore = localStorage.getItem('visitedBefore');

  //   // Check if it's the user's first visit
  //   if (!hasVisitedBefore) {
  //     setShowBanner(true);
  //     localStorage.setItem('visitedBefore', 'true');
  //   }
  // }, []);

  // const handleCloseBanner = () => {
  //   setShowBanner(false);
  // };

  let jsonData = localStorage.getItem('userDetails');
  const userData = JSON.parse(jsonData);
  
  const renderRoutes = (items) => {
    return items.map((item, index) => (
      <React.Fragment key={index}>
         {item.path && item.component && (
        <Route exact path={item.path} element={<item.component />} />
      )}
        {item.submenu && item.submenu.length > 0 && (
          renderRoutes(item.submenu)
        )}
      </React.Fragment>
    ));
  };

  // if(userData?.roles?.includes('dataEntry')){
  //   return (
  //     <Provider store={store}>
  //     <div>
  //       <BrowserRouter>
  //       <ScrollToTop />
  //         {localStorage.getItem('no-header') ? null : <Header />}

  //       <Routes>
  //           <Route path="/"  element={<AdminDashboard/>} />
  //           <Route path="*" element={<NotFoundPage />} />
  //           </Routes>
  //           </BrowserRouter>
  //           </div>
  //           </Provider>
  //   )
  // }

 return (
   <>
     <Provider store={store}>
       <div>
         <BrowserRouter>
           <ScrollToTop />
           {localStorage.getItem('no-header') ? null : <Header />}

           <Routes>
             <Route path="/customer-feedback" element={<CustomerFeadback />} />
             <Route path="/policyupload/:policyId" element={<PolicyUpload />} />
             <Route
               path="/dashpolicy/:insuranceType/:policyId"
               element={<PolicyUploadAdmin />}
             />
             <Route path="/" element={<Home />} />
             <Route path="*" element={<NotFoundPage />} />
             <Route path="/products/:first" exact element={<Products />} />
             <Route
               path="/products/:first/:second"
               exact
               element={<HealthInsurance />}
             />
             <Route
               path="/products/:first/:second/:third"
               exact
               element={<LifeInsurance />}
             />

             {/* for the brand page */}
             <Route exact path="/brand/:name" element={<MainBrandPage />} />

             {/* Static Route */}
             {userData?.roles?.includes('admin') ? (
               <Route path="/policy-form" element={<PolicyForm />} />
             ) : (
               <Route
                 path="/policy-form"
                 element={
                   <Navigate to="/signin-signup?sourcePage=header" replace />
                 }
               />
             )}
             <Route exact path="/signin-signup" element={<SigninSignup />} />
             <Route exact path="/term-insurance" element={<Term />} />
             <Route exact path="/get-quotes" element={<GetQuotes />} />
             <Route exact path="/form-2" element={<Form2 />} />
             <Route exact path="/car-insurance" element={<CarInsurance />} />
             <Route exact path="/car/form-2" element={<CarForm2 />} />
             <Route exact path="/car/form-3" element={<CarForm3 />} />
             <Route exact path="/car/form-4" element={<CarForm4 />} />
             <Route exact path="/car/form-5" element={<CarForm5 />} />
             <Route exact path="/car/form-6" element={<CarForm6 />} />
             <Route exact path="/car/form-7" element={<CarForm7 />} />
             <Route exact path="/car/form-8" element={<CarForm8 />} />
             <Route exact path="/car/form-9" element={<CarForm9 />} />
             <Route exact path="/car/form-10" element={<CarForm10 />} />
             <Route exact path="/car/form-11" element={<CarForm11 />} />
             <Route
               exact
               path="/brand-new-model/brand-model"
               element={<BrandModel />}
             />
             <Route
               exact
               path="/brand-new-model/select-model"
               element={<SelectModel />}
             />
             <Route
               exact
               path="/brand-new-model/car-details/1"
               element={<CarDetailsForm1 />}
             />
             <Route
               exact
               path="/brand-new-model/car-details/2"
               element={<CarDetailsForm2 />}
             />
             <Route
               exact
               path="/brand-new-model/car-details/3"
               element={<CarDetailsForm3 />}
             />
             <Route
               exact
               path="/brand-new-model/car-details/4"
               element={<CarDetailsForm4 />}
             />
             <Route
               exact
               path="/brand-new-model/car-details/5"
               element={<CarDetailsForm5 />}
             />
             <Route
               exact
               path="/brand-new-model/car-details/6"
               element={<CarDetailsForm6 />}
             />
             <Route
               exact
               path="/brand-new-model/car-form-preview"
               element={<CarFormPreview />}
             />
             <Route
               exact
               path="/policy-details/type-of-policy"
               element={<TypeofPolicy />}
             />
             {/* <Route exact path='/policy-details/type-of-policy2' element={<TypeofPolicy2 />} /> */}
             <Route
               exact
               path="/policy-details/type-of-policy3"
               element={<TypeOfPolicy3 />}
             />
             <Route
               exact
               path="/policy-details/standalone-tp"
               element={<StandaloneTp />}
             />
             <Route
               exact
               path="/policy-details/prev-insurance"
               element={<PrevInsuranceDetails />}
             />
             <Route exact path="/intro-screen" element={<IntroScreen />} />
             <Route
               exact
               path="/life-intro-screen"
               element={<LifeIntroScreen />}
             />
             <Route exact path="/life-quotes" element={<LifeMainScreen />} />
             <Route
               exact
               path="/car-insurance/main"
               element={<CarMainPage />}
             />
             <Route
               exact
               path="/car-insurance/insurance-popup"
               element={<InsurancePopup />}
             />
             <Route
               exact
               path="/car-insurance/view-details/1"
               element={<ViewDetailsCar1 />}
             />
             <Route
               exact
               path="/car-insurance/payment/1"
               element={<CarPayment1 />}
             />
             <Route
               exact
               path="/car-insurance/dynamic-variants/1"
               element={<DynamicVariants />}
             />
             <Route
               exact
               path="/car-insurance/payment/3"
               element={<CarPayment3 />}
             />
             <Route
               exact
               path="/car-insurance/payment/4"
               element={<CarPayment4 />}
             />
             <Route
               exact
               path="/car-insurance/payment/4"
               element={<CarPayment4 />}
             />
             <Route
               exact
               path="/car-insurance/payment/instructions"
               element={<Instructions />}
             />
             <Route
               exact
               path="/car-insurance/preview-after-payment"
               element={<PreviewAfterPayment />}
             />
             <Route
               exact
               path="/car-insurance/payment/5"
               element={<CarPayment5 />}
             />
             <Route
               exact
               path="/car-insurance/car-payment-success"
               element={<CarPaymentSuccess />}
             />
             <Route
               exact
               path="/dashboard/agent"
               element={<AgentDashboard />}
             />

             {/* reset password */}
             <Route path="/reset-password" element={<MobileNumberPage />} />
             <Route path="/reset-password/otp" element={<OTPPage />} />
             <Route
               path="/reset-password/new-password"
               element={<PasswordPage />}
             />
             <Route path="/reset-password/success" element={<SuccessPage />} />

             {/* <Route exact path='/about-simpliinsure' element={<AboutUs />} /> */}
             <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
             <Route exact path="/terms" element={<Terms />} />
             {/* <Route exact path='/contact-us' element={<ContactUs />} /> */}
             <Route exact path="/refunds" element={<Refunds />} />
             <Route exact path="/partner/:id" element={<POS />} />
             <Route exact path="/partner" element={<POS />} />
             {/* <Route exact path='/corporate-insurance' element={<Corporate />} /> */}
             {/* <Route exact path='/products' element={<Products />} /> */}

             {userData &&
               userData.roles &&
               (userData.roles.includes('admin') ||
                 userData.roles.includes('dataEntry')) && (
                 <Route path="/admin" element={<AdminDashboard />} />
               )}

             <Route path="/details" element={<Basic />} />
             <Route path="/lang" element={<Language />} />
             <Route path="/sengment" element={<SEGMENT />} />
             <Route path="/documents" element={<Upload />} />
             <Route path="/review" element={<Review />} />
             <Route path="/pos" element={<Pos />} />
             <Route path="/done" element={<Done />} />

             <Route exact path="/sitemap" element={<Sitemap />} />
             <Route
               exact
               path="/about/prashant-nimgade"
               element={<PransantPage />}
             />
             <Route
               exact
               path="/about/avinash-shende"
               element={<ShandePage />}
             />
             <Route exact path="/about/sachin-pande" element={<PandePage />} />
             <Route exact path="/career" element={<Careers />} />
             <Route exact path="/newsletter" element={<NewsLatter />} />
             <Route exact path="/faq" element={<FAQSection />} />

             {/* blog */}
             <Route exact path="/blog" element={<BlogList />} />
             <Route exact path="/blog/:slug" element={<BlogDetail />} />

             {/* wix page */}
             {/* <Route path="/contact-us/insuranceapplication" element={<WixPage/>} /> */}
             {/* <Route exact path='/new-contact' element={<ContactUsPage />} /> */}
             {renderRoutes(menuItems)}
           </Routes>
         </BrowserRouter>

         {/* WhatsApp Icon */}
         <div className="whatsapp-icon">
           <a
             href="https://wa.me/+919606045764?text=Hi%20SimpliInsure,%20I%20have%20a%20query%20regarding%20my%20insurance%20policy."
             target="_blank"
             rel="noopener noreferrer"
           >
             <IoLogoWhatsapp size={45} />
           </a>
         </div>
       </div>
     </Provider>
   </>
 );
}

export default App